import React from "react";
import "./Header.css"
import { TbPhoneCall } from "react-icons/tb";
import gif from "../images/ezgif.com-crop (1).gif";

function Header() {
  return (
    <div id="main">
      <section id="home" class="home">
        <div className="name">
    
          <img src={gif}/>
        
          <div className="header-btns">
            <a href="tel:+918980639900" className="header-btn">
              Call Us Now&nbsp;<TbPhoneCall/>
            </a>
          </div>
        </div>

        <div class="wave wave1"></div>
        <div class="wave wave2"></div>
        <div class="wave wave3"></div>
      </section>
    </div>
  );
}
export default Header;




// import React from "react";
// import "./Header.css";
// import { TbPhoneCall } from "react-icons/tb";

// function Header() {
//   return (
//     <div id="main">
//       <section id="home" className="home">
//         <div className="name">
//           <h1>
//             Welcome To
//             <br />
//             Linkview
//             <br />
//             Digital Hub<span className="bouncing-period">.</span>
//           </h1>
//           <p className="details">
//             <span> Website </span> <span>• Branding </span>{" "}
//             <span>• Digital Marketing </span>
//           </p>
//           <div className="header-btns">
//             <a href="tel:+918980639900" className="header-btn">
//               Call Us Now&nbsp;<TbPhoneCall />
//             </a>
//           </div>
//         </div>
//         <div className="wave wave1"></div>
//         <div className="wave wave2"></div>
//         <div className="wave wave3"></div>
//       </section>
//     </div>
//   );
// }

// export default Header;





