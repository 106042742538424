


import React from "react";
// Import necessary icons from react-icons
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineMarkEmailRead, MdAddCall } from "react-icons/md";
import { IoLogoWhatsapp, IoIosMail } from "react-icons/io";

import { Link } from "react-router-dom";
// Import your images
import whatsappnow from "../images/whatsppnow.gif"; // Ensure the filename is correct
import fimage1 from '../../src/images/main.png';
// Import CSS
import './Footer.css';

const Footer = () => {
  return (
    <> 
      <div className="container">
        <div className="left">
          <img className="logo" src={fimage1} alt="Logo" />
          <p className="desc">"Empowering Brands, Enhancing Digital Journeys - Linkview Digital Hub."</p>
          <div className="social-container">
            {/* Use anchor tags for external links */}
            <a href="https://www.facebook.com/linkview.digitalmarketing" target="_blank" className="social-icon" style={{ backgroundColor: '#385999' }}>
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/linkview.marketing" target="_blank" className="social-icon" style={{ background: 'linear-gradient(29.61deg,#f38334 0% , #da2e7d 50.39%,#6b54c6 100%)' }}> 
              <FaInstagram />
            </a>
            <a href="mailto:digital@linkview.in?subject=Inquiry&body=Hi%20Linkview%20Digital%20Hub" className="social-icon" style={{ backgroundColor: '#D44638' }}>
              <MdOutlineMarkEmailRead />
            </a>
            <a href="https://wa.me/918490084721?text=Hello%20Linkview%20Digital%20Hub!" target="_blank" className="social-icon" style={{ backgroundColor: '#25D366' }}>
              <IoLogoWhatsapp />
            </a>
            <a href="https://in.linkedin.com/company/linkviewdigital" target="_blank" className="social-icon" style={{ backgroundColor: '#0077B5' }}>
              <FaLinkedinIn />
            </a>
          </div>
        </div>
        <div className="center">
          <h3 className="title">Quick Links</h3>
          <ul className="list">
            {/* Correctly using Link for internal navigation */}
            <li className="list-item"><Link to="/">Home</Link></li>
            <li className="list-item"><Link to="/blog">Blog</Link></li>
            <li className="list-item"><Link to="/faq">FAQ</Link></li>
            <li className="list-item"><Link to="/terms-and-conditions">T&C</Link></li>
            <li className="list-item"><Link to="/contact">Contact</Link></li>
            <li className="list-item"><Link to="/about">About</Link></li>
            <li className="list-item"><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li className="list-item"><Link to="/career-with-us">Career With Us</Link></li>
          </ul>
        </div>
        <div className="right">
          <h3 className="title">Contact Details</h3>
          <div className="contact-item">
            <FaLocationDot style={{ marginRight: '10px' }} />
            D-7, Bhagvan Nagar Society, Opp. Vikas Shopper,<br/> Sarthana Jagatnaka, Surat
          </div>
          <div className="contact-item">
            <MdAddCall style={{ marginRight: '10px' }} /> +91 84900 84721
          </div>
          <div className="contact-item">
            <IoIosMail style={{ marginRight: '10px' }} />
            digital@linkview.in
          </div>
        </div>
      </div>
      <div className="copyright" >
        <h4 >
          © Copyright <b>Linkview Marketing</b>. All Rights Reserved
        </h4>
      </div>
      <div className="whatsapp-fixed-icon">
        <a href="https://api.whatsapp.com/send?phone=918980639900" title="WhatsApp Now For Inquiry" target="_blank" rel="noopener noreferrer">
          <img src={whatsappnow} alt="WhatsApp Now For Inquiry" title="WhatsApp Now For Inquiry" style={{ width: '60px' }} />
        </a>
      </div>
    </>
  );
};

export default Footer;

