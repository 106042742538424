// import React, { useState, useEffect, useRef } from "react";
// import "./Counter.css";

// export default function Counter() {
//   const counters = [
//     { id: 1, icon: "🍴", target: 150, text: "Projects Completed" },
//     { id: 2, icon: "😊", target: 100, text: "Happy Clients" },
//     { id: 3, icon: "📜", target: 6, text: "Years Experience" },
//   ];

//   // Create state for each counter to start from 0
//   const [counts, setCounts] = useState(counters.map(counter => ({ ...counter, number: 0 })));

//   // Ref for the counter container to observe when it comes into view
//   const counterContainerRef = useRef(null);

//   // Count up to the target number
//   const countUp = (id, target) => {
//     setCounts(currentCounts =>
//       currentCounts.map(counter => {
//         if (counter.id === id) {
//           if (counter.number < target) {
//             // Increment number
//             return { ...counter, number: counter.number + 1 };
//           }
//         }
//         return counter; // Leave all other counters as is
//       }),
//     );
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       entries => {
//         // Check if the element is intersecting
//         if (entries[0].isIntersecting) {
//           counters.forEach(counter => {
//             // Start counting for each counter
//             let intervalId = setInterval(() => {
//               countUp(counter.id, counter.target);
//             }, 5000 / counter.target); // Adjust speed here as needed

//             // Clear interval when target is reached
//             setTimeout(() => clearInterval(intervalId), 1000);
//           });
//           // Unobserve after starting count up
//           observer.unobserve(counterContainerRef.current);
//         }
//       },
//       { threshold: 0.5 } // Trigger when half of the element is in view
//     );

//     // Observe the ref element
//     if (counterContainerRef.current) {
//       observer.observe(counterContainerRef.current);
//     }

//     // Cleanup the observer on component unmount
//     return () => {
//       if (counterContainerRef.current) {
//         observer.unobserve(counterContainerRef.current);
//       }
//     };
//   }, [counters]);

//   return (
//     <div className="counter-container" ref={counterContainerRef}>
//       {counts.map(counter => (
//         <div key={counter.id} className="counter">
//           <div className="icon">{counter.icon}</div>
//           <div className="number">{counter.number}</div>
//           <div className="text">{counter.text}</div>
//         </div>
//       ))}
//     </div>
//   );
// }






// import React, { useState, useEffect } from "react";
// import VisibilitySensor from "react-visibility-sensor";
// import { FaRegFaceSmile } from "react-icons/fa6";
// import happy from "../images/success-unscreen.gif";

// import "./Counter.css";

// export default function Counter() {
//     const [visible, setVisible] = useState(false);

//     const counters = [
//       { id: 1, icon: happy, number: 150, text: "Projects Completed" },
//       { id: 2, icon: happy, number: 300, text: "Happy Clients" },
//       { id: 3, icon: happy, number: 2500, text: "Social Media Followers" },
//   ];

//     const [counts, setCounts] = useState(counters.map(counter => ({ ...counter, number: 0 })));

//     useEffect(() => {
//         if (visible) {
//             const intervalIds = counters.map((counter, index) => {
//                 return setInterval(() => {
//                     setCounts(prevCounts => {
//                         if (prevCounts[index].number < counter.number) {
//                             const newCounts = [...prevCounts];
//                             newCounts[index].number += 1;
//                             return newCounts;
//                         } else {
//                             clearInterval(intervalIds[index]);
//                             return prevCounts;
//                         }
//                     });
//                 }, 50);
//             });

//             return () => {
//                 intervalIds.forEach(clearInterval);
//             };
//         }
//     }, [visible]);

//     return (
//       <VisibilitySensor
//           onChange={(isVisible) => setVisible(isVisible)}
//           partialVisibility
//           offset={{ top: 10 }}
//           delayedCall
//       >
//           <div className="counter-container">
//               {counts.map((counter) => (
//                   <div key={counter.id} className="counter">
//                       <div className="icon">
//                           {/* Use the imported GIF */}
//                           <img src={counter.icon} alt="icon" />
//                       </div>
//                       <div className="number">{counter.number}</div>
//                       <div className="text">{counter.text}</div>
//                   </div>
//               ))}
//           </div>
//       </VisibilitySensor>
//   );
// }




import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
// import gif1 from "../images/clipboard.gif";
// import gif2 from "../images/angry.gif";
// import gif3 from "../images/social-media.gif";
import i1 from "../images/i1.svg";
import i2 from "../images/i2.svg";
import i3 from "../images/i3.svg";


import "./Counter.css";

export default function Counter() {
    const [visible, setVisible] = useState(false);

    const counters = [
        { id: 1, icon: i1, target: 150, text: "Projects Completed" },
        { id: 2, icon: i2, target: 300, text: "Happy Clients" },
        { id: 3, icon: i3, target: 2500, text: "Social Media Followers" },
    ];

    const [counts, setCounts] = useState(counters.map(counter => ({ ...counter, number: 0 })));
    const [startTime, setStartTime] = useState(null);
    const duration = 5000; // Duration in milliseconds

    useEffect(() => {
        if (visible) {
            setStartTime(Date.now());
        }
    }, [visible]);

    useEffect(() => {
        if (startTime !== null) {
            const intervalId = setInterval(() => {
                const currentTime = Date.now();
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(1, elapsedTime / duration);

                setCounts(prevCounts => {
                    return prevCounts.map(counter => ({
                        ...counter,
                        number: Math.min(Math.round(counter.target * progress), counter.target)
                    }));
                });

                if (progress >= 1) {
                    clearInterval(intervalId);
                }
            }, 50);

            return () => clearInterval(intervalId);
        }
    }, [startTime]);

    return (
        <VisibilitySensor
            onChange={(isVisible) => setVisible(isVisible)}
            partialVisibility
            offset={{ top: 10 }}
            delayedCall
        >
            <div className="counter-container">
                {counts.map((counter) => (
                    <div key={counter.id} className="counter">
                        <div className="icon">
                            <img src={counter.icon} alt="icon" />
                        </div>
                        <div className="number">{counter.number} +</div>
                        <div className="text">{counter.text}</div>
                    </div>
                ))}
            </div>
        </VisibilitySensor>
    );
}

