
import React from 'react'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import FAQ from "../images/FAQ.png"
 

const Faq = () => {
  return (
    <>  
    <div >
     <Navbar/>
      
    </div>
    <div class="faqheader">
    <h1 class="faqheader-title">FAQ</h1>
    <p class="faqheader-desc">Frequently Ask Questions</p>

    {/* <div class="search">
      <input type="text" placeholder="Search...." />
      <button>Search</button>
    </div> */}
  </div>
  
  <div class="faq">
      <div class="faq-name">
          <h1 class="faq-header">
              Have <br/> Questions ?</h1>
              <img src={FAQ} alt="" class="faq-img"/>
      </div>
      <div class="faq-box">
          <div class="faq-wrapper">
              <input type="checkbox" class="faq-trigger" id="faq-trigger-1"/>
              <label class="faq-title" for="faq-trigger-1">What is a digital marketing agency?</label>
              <div class="faq-details">
                  <p>A digital marketing agency is a company that provides a range of online marketing services to help businesses promote their products or services on digital platforms. These services may include SEO, social media marketing, content creation, email marketing, and more.</p>
              </div>
          </div>
          <div class="faq-wrapper">
              <input type="checkbox" class="faq-trigger" id="faq-trigger-2"/>
              <label class="faq-title" for="faq-trigger-2">Why do businesses need a digital marketing agency?</label>
              <div class="faq-details">
                  <p>Businesses need digital marketing agencies to leverage the power of online platforms and reach their target audience effectively. Digital marketing agencies have expertise in various online channels and strategies, helping businesses increase brand visibility, generate leads, and drive sales.</p>
              </div>
          </div>
          <div class="faq-wrapper">
              <input type="checkbox" class="faq-trigger" id="faq-trigger-3"/>
              <label class="faq-title" for="faq-trigger-3">What services do digital marketing agencies offer?</label>
              <div class="faq-details">
                  <p>Digital marketing agencies offer a variety of services, including search engine optimization (SEO), social media marketing, content creation, pay-per-click (PPC) advertising, email marketing, web design, analytics, and more. The services provided can vary based on the agency's specialization and client needs.</p>
              </div>
          </div>
          <div class="faq-wrapper">
              <input type="checkbox" class="faq-trigger" id="faq-trigger-4"/>
              <label class="faq-title" for="faq-trigger-4">How do digital marketing agencies measure success?</label>
              <div class="faq-details">
                  <p>Success metrics for digital marketing agencies depend on the specific goals of a campaign. Common metrics include website traffic, conversion rates, social media engagement, return on investment (ROI), and overall online visibility. Agencies use analytics tools to track and analyze these metrics.</p>
              </div>
          </div>
          <div class="faq-wrapper">
              <input type="checkbox" class="faq-trigger" id="faq-trigger-5"/>
              <label class="faq-title" for="faq-trigger-5">How much does it cost to hire a digital marketing agency?</label>
              <div class="faq-details">
                  <p>The cost of hiring a digital marketing agency varies based on factors such as the scope of services, the level of expertise, and the size of the agency. Some agencies charge a flat fee, while others may work on a retainer or project basis. It's essential to discuss and agree upon pricing structures before starting a project.</p>
              </div>
          </div>


      </div>
  </div>
  <Footer/>
  </>
  )
}

export default Faq
