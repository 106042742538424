// // import React from "react";
// // import aboutimage from "../../src/images/aboutvector.png";
// // import "./About.css"

// // export default function About() {
// //   return (
// //     <>
// //       <h1 style={{ color: "white" }} classNameName="headingtxt">ABOUT US</h1>
// //       <div id="about">
// //         <div classNameName="about-image">
// //           <img src={aboutimage} alt=""></img>
// //         </div>

// //         <div classNameName="about-text">
// //           <p>
// //             Linkview Digital Hub, a Surat-based leader in digital marketing and
// //             branding, boasts over 6 years of industry experience. We offer a
// //             full suite of services including Search Marketing, Lead Generation,
// //             Social Media, and Email Marketing. Our integrated approach ensures
// //             enhanced brand recall and superior ROI for our diverse clientele.
// //           </p>
         
// //         </div>
// //       </div>
// //     </>
// //   );
// // }



// import React from "react";
// import aboutimage from "../../src/images/aboutvector.png";
// import "./About.css"; 

// export default function About() {
//   return (
//     <>
//       <h1 classNameName="headingtxt">About Us</h1>
//       <div id="about">
//         <div classNameName="about-image">
//           <img src={aboutimage} alt="About Us"></img>
//         </div>
//         <div classNameName="about-text">
//           <p>
//             Linkview Digital Hub, a Surat-based leader in digital marketing and
//             branding, boasts over 6 years of industry experience. We offer a
//             full suite of services including Search Marketing, Lead Generation,
//             Social Media, and Email Marketing. Our integrated approach ensures
//             enhanced brand recall and superior ROI for our diverse clientele.
//           </p>
//         </div>
//       </div>      
//     </>
//   );
// }



// import React from "react";
// import aboutimage from "../../src/images/aboutvector.png";
// import "./About.css"

// export default function About() {
//   return (
//     <>
//       <h1 style={{ color: "white" }} classNameName="headingtxt">ABOUT US</h1>
//       <div id="about">
//         <div classNameName="about-image">
//           <img src={aboutimage} alt=""></img>
//         </div>

//         <div classNameName="about-text">
//           <p style={{color:'white',textAlign:'justify'}}>
//             Linkview Digital Hub, a Surat-based leader in digital marketing and
//             branding, boasts over 6 years of industry experience. We offer a
//             full suite of services including Search Marketing, Lead Generation,
//             Social Media, and Email Marketing. Our integrated approach ensures
//             enhanced brand recall and superior ROI for our diverse clientele.
//           </p>
         
//         </div>
//       </div>
//     </>
//   );
// }



import React from "react";
import "./About.css";

import icon1 from "../images/shopping-bag-svgrepo-com.svg";
import icon2 from "../images/bulb-svgrepo-com.svg";
import icon3 from "../images/laptop-svgrepo-com.svg";
import icon4 from "../images/marketing-speaker-svgrepo-com.svg";



const MarketingBox = ({ icon, title }) => (
  <div className="marketing-box">
    <img src={icon} alt={title} className="marketing-icon" />
    <h3 className="marketing-title">{title}</h3>
  </div>
);


const About = () => {
  const services  = [
    {
      id: 1,
      icon: icon1, 
      title: 'Discover, Explore the Product',
    },
    {
      id: 2,
      icon: icon2,
      title: 'Art Direction & Brand Strategy',
    },
    {
      id: 3,
      icon: icon3,
      title: 'Product UX, Design & Development',
    },
    {
      id: 4,
      icon: icon4,
      title: 'Marketing Strategy & SEO Campaigns',
    },
  ];
  return (
    <div className="marketing-container">
    <div className="marketing-header">
      <h1 style={{backgroundImage: 'linear-gradient(250deg, #e59124, white)', WebkitBackgroundClip: 'text', color: 'transparent'}}>Why you need digital marketing</h1>
    </div>
    <div className="marketing-services">
      {services.map((service, index) => (
        <MarketingBox key={index} icon={service.icon} title={service.title} />
      ))}
    </div>
  </div>
  );
};

export default About;



