import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import image11 from "../images/smback.jpg";
import "./Privacy.css"


const Term = () => {
  return (
    <div className="App">
      <Navbar />

      <div classNameName="container">
        <div>
          <img
            src={image11}
            style={{ opacity: "0.5" }}
            alt="Service Background"
            width="1700px"
            height="500px"
          />
          <div className="overlay-text">
            <h1
              className="animated-text"
              style={{ textAlign: "center", color: "white" }}
            >
              Terms and Conditions
            </h1>
          </div>
        </div>
      </div>

      <div className="privacy-policy">
        <div class="section">
          <div class="container">
            <div class="title">
              <h1>Terms and Conditions</h1>
            </div>

            <h3>
            Welcome to Linkview Marketing Hub. By accessing our website or using our services, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our website or services.
              <br />
              <br />
              Services :<br />
              <div className="p">
                {" "}
                Linkview Marketing Hub provides a range of digital marketing services, including but not limited to search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, and content creation. Specific terms related to each service will be outlined in service agreements or project briefs.
              </div>
              <br />
              <br />
              Use of Our Services :<br />
              <br />
    
              <div className="p">
                {" "}
                You agree to use our services only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of Linkview Marketing Hub. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our website.
                <br />
                <br />
              </div>
              Intellectual Property :<br />
              <div className="p">
                {" "}
                All content included on our site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Linkview Marketing Hub or its content suppliers and protected by international copyright laws.
                <br />
                <br />
              </div>
              Termination :
              <br />
              <div className="p">
              We may terminate or suspend access to our services immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms and Conditions.
                <br />
                <br />
              </div>
              Changes to Terms and Conditions :
              <br />
              <div className="p">
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. It is your responsibility to check this page periodically for changes.
              </div>
              <br />
              Contact Us :
              <br />
              <div className="p">
                {" "}
                If you have any questions about these Terms, please contact us at contact@linkview.in
              </div>
              <br />
              <br />
              <br />
            </h3>

            <h2> - LINKVIEW DIGITAL HUB -</h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Term