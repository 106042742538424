import "./Blog.css";
import React from "react";

import Navbar from "../Components/Navbar";

import Footer from "../Components/Footer";

import blog1 from "../images/blog1.jpg";
import blog2 from '../images/blog2.png';
import blog3 from '../images/blog3.png';
import blog4 from '../images/blog4.png';
import blog5 from '../images/blog5.png';
import blog6 from '../images/blog6.png';

 import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <Navbar />
      <div className="blog-section">
      
        <section id="blog">
          <div className="blog-heading">
           
            <h3>Blogs</h3>
          </div>

          <div className="blog-container">

            <div className="blog-box">
              <div className="blog-img">
                <img src={blog1} />
              </div>

              <div className="blog-text">
                
                <a href="#" className="blog-title">
                What role does social media play in a digital marketing strategy?
                </a>
                <p>
                Social media is pivotal in digital marketing, enhancing brand visibility, fostering engagement, and driving traffic. It enables direct customer interaction, building trust and loyalty. Through targeted content, companies can reach specific audiences, gather insights, and boost conversions, making it a key component for comprehensive digital strategy success.
                </p>

                <Link to="/blogone">Read More</Link>
              </div>
            </div>

            <div className="blog-box">
              <div className="blog-img">
                <img src={blog5} />
              </div>

              <div className="blog-text">
                
                <a href="#" className="blog-title">
                Why are SEO strategies crucial for a website's success?
                </a>
                <p>
                SEO strategies enhance a website's visibility on search engines, driving more organic traffic, improving user experience, and increasing the likelihood of converting visitors into clients. By optimizing content, leveraging keywords, and ensuring mobile-friendliness, businesses can effectively rise above competitors, fostering long-term growth and brand loyalty.
                </p>
                <a href="blogfive">Read More</a>
              </div>
            </div>

            <div className="blog-box">
              <div className="blog-img">
                <img src={blog6} />
              </div>

              <div className="blog-text">
                
                <a href="#" className="blog-title">
                How can small businesses benefit from social media marketing?
                </a>
                <p>
                Social media marketing allows small businesses to reach a wider audience at a lower cost, engage directly with customers, and boost brand awareness, leading to increased clientele and growth. Additionally, it offers valuable insights into customer preferences and behavior, enabling businesses to tailor their strategies for more personalized and effective communication.
                </p>
                <a href="/blogsix">Read More</a>
              </div>
            </div>

            <div className="blog-box">
              <div className="blog-img">
                <img src={blog2} />
              </div>

              <div className="blog-text">
                
                <a href="#" className="blog-title">
                What are the key benefits of using Facebook Ads?
                </a>
                <p>
                Facebook Ads boost visibility and sales through precise targeting and analytics, allowing for real-time optimization. It's cost-effective, reaching a wide audience with tailored messages, enhancing brand presence and driving conversions, crucial for growth and attracting clients.
                </p>
                <a href="/blogtwo">Read More</a>
              </div>
            </div>

            <div className="blog-box">
              <div className="blog-img">
                <img src={blog3} />
              </div>

              <div className="blog-text">
                
                <a href="#" className="blog-title">
                How do Google Ads improve online visibility?
                </a>
                <p>
                Google Ads significantly boost online visibility by strategically placing a business's website at the very top of search engine results, accurately targeting specific, interested audiences, and substantially increasing the chances of attracting potential clients.
                </p>
                <a href="blogthree">Read More</a>
              </div>
            </div>

            <div className="blog-box">
              <div className="blog-img">
                <img src={blog4} />
              </div>

              <div className="blog-text">
                
                <a href="#" className="blog-title">
                What is the importance of branding in digital marketing?
                </a>
                <p>
                Branding in digital marketing establishes a unique identity, builds trust, and differentiates a business from competitors, crucial for attracting and retaining clients in a crowded digital space. It fosters a strong, lasting emotional connection.
                </p>
                <a href="blogfour">Read More</a>
              </div>
            </div>

           
          </div>
        </section>
       
      </div>
      <Footer />
    </>
  );
};

export default Blog;
