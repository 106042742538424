

import React, { useEffect, useRef } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { HiOutlineLightBulb } from "react-icons/hi";
import { SlSocialFacebook } from "react-icons/sl";
import { SiGoogleadsense } from "react-icons/si";
import "./service.css";

import { Link } from "react-router-dom";
import sideImage1 from "../../images/creative_graphic.png";
import sideImage2 from "../../images/socialmedia_Design.png";
import sideImage3 from "../../images/advertise_service.png";


import { TbTargetArrow } from "react-icons/tb";
import { RiHandCoinLine } from "react-icons/ri";
import { GiCash } from "react-icons/gi";

import { AiOutlineGlobal } from "react-icons/ai";
import { TbTarget } from "react-icons/tb";
import { MdConnectWithoutContact } from "react-icons/md";

import { HiOutlineSpeakerphone } from "react-icons/hi";
import { BiSolidLike } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";

const Graphicdesign = () => {
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);

  const checkScroll = () => {
    [boxRef1, boxRef2, boxRef3].forEach((ref) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          ref.current.classList.add("in-view");
        } else {
          ref.current.classList.remove("in-view");
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <>
     <Navbar />
     <div className="main-container">

      <div className="service-pages">

        <section id="home" className="home">
          <h1>Services &gt;</h1>
          <h2>Graphics Design</h2>
          <div className="wave wave1"></div>
          <div className="wave wave2"></div>
          <div className="wave wave3"></div>
        </section>

        <h1 className="service-text">We Provide</h1>
        <section className="service-section">
          <div className="row">
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <HiOutlineLightBulb />
                  </i>
                </div>
                 <br/>
                <h3>Creative Ads Design</h3>
                <p>
                Transform your brand with eye-catching Creative Ads Design! Our expert team crafts ads that captivate, resonate, and drive engagement. Elevate your message and make a lasting impression. Let's create unforgettable advertising together.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <SlSocialFacebook />
                  </i>
                </div>
                 <br/>
                <h3>Social Media Design</h3>
                <p>
                Boost your online presence with our Social Media Design services! Expertly crafted visuals that engage, inspire, and connect with your audience. Elevate your social profiles and make your brand stand out. Let's make social media your power tool.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <SiGoogleadsense />
                  </i>
                </div>
                 <br/>
                <h3>Advertisement Design</h3>
                <p>
                Unlock the power of your brand with our Advertisement Design services! Create compelling ads that grab attention, spark interest, and drive action. Stand out, captivate your audience, and accelerate your growth. Let's craft ads that resonate and convert.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
          </div>
          <div ref={boxRef1} className="content-box">
              <div className="side-image">
                <img src={sideImage1} alt="Service" />
              </div>

              <div className="side-text">
                <h1>
                Looking to captivate your audience with unforgettable ads?
                </h1>
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <TbTargetArrow />
                  </i>
                  <p className="p-text">
                    <b>Captivating Ads:</b>
                    <br />
                    Unforgettable ads capture attention and leave a lasting impression, driving engagement and brand recall for maximum impact.

                  </p>
                </div>
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <RiHandCoinLine />
                  </i>
                  <p className="p-text">
                    <b>Creative Storytelling:</b>
                    <br />
                    Compelling narratives and visuals in ads create emotional connections, resonating with audiences and enhancing brand perception.
                  </p>
                </div>{" "}
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <GiCash />
                  </i>
                  <p className="p-text">
                    <b>Strategic Placement:</b>
                    <br />
                    Strategically placing ads across platforms and channels ensures broad reach and exposure to target audiences, maximizing campaign effectiveness.
                  </p>
                </div>
              </div>
            </div>

            <div
              ref={boxRef2}
              className="content-box"
              style={{ background: "whitesmoke", borderRadius: "4%" }}
            >
              <div className="side-image">
                <img src={sideImage2} alt="Service" />
              </div>

              <div className="side-text">
                <h1>
                How can your Social Media Design service enhance my brand's online presence?

                </h1>
                <div
                  className="icon-paragraph-container"
                  style={{ color: "black" }}
                >
                  <i className="sicons left-icon">
                    <AiOutlineGlobal />
                  </i>
                  <p className="p-text">
                    <b>Strategic Visual Consistency:</b>
                    <br />
                    Our social media design ensures your brand's consistent visual identity, boosting recognition and trust among your audience.
                  </p>
                </div>
                <div
                  className="icon-paragraph-container"
                  style={{ color: "black" }}
                >
                  <i className="sicons left-icon">
                    <TbTarget />
                  </i>
                  <p className="p-text">
                    <b>Engaging Visual Content Creation:</b>
                    <br />
                    Engage your audience with visually compelling content crafted by our expert team, driving higher levels of interaction.
                  </p>
                </div>{" "}
                <div
                  className="icon-paragraph-container"
                  style={{ color: "black" }}
>
                  <i className="sicons left-icon">
                    <MdConnectWithoutContact />
                  </i>
                  <p className="p-text">
                    <b>Tailored Content for Each Platform:</b>
                    <br />
                    Tailored designs for each platform optimize impact, maximizing the effectiveness of your social media presence.
                  </p>
                </div>
              </div>
            </div>

            <div ref={boxRef3} className="content-box">
              <div className="side-image">
                <img src={sideImage3} alt="Service" />
              </div>

              <div className="side-text">
                <h1>
                What advantages does your Advertisement Design service offer for my marketing campaigns?

                </h1>
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <HiOutlineSpeakerphone />
                  </i>
                  <p className="p-text">
                    <b>Strategic Visual Communication:</b>
                    <br />
                    Our advertisement design services excel in strategic visual communication, ensuring your brand message is conveyed effectively.
                  </p>
                </div>
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <BiSolidLike />
                  </i>
                  <p className="p-text">
                    <b>Eye-Catching Creativity for Audience Capture:</b>
                    <br />
                    Capture your audience's attention with creative and eye-catching designs that leave a lasting impression.

                  </p>
                </div>{" "}
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <IoIosPeople />
                  </i>
                  <p className="p-text">
                    <b>Multi-Platform Adaptability:</b>
                    <br />
                    Our designs are adaptable across multiple platforms, maximizing visibility and impact for your marketing campaigns.
                  </p>
                </div>
              </div>
            </div>
        </section>
      </div>
      </div>

      <div className="footer">
        <center>
<Footer/>
</center>
</div>
    </>
  );
};

export default Graphicdesign;
