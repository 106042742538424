
import React from 'react';
import './Pricing.css'; 
import { Link } from 'react-router-dom';
import startup from "../images/startup.svg";
import growth from "../images/growth.svg";
import premium from "../images/premium.svg";

const plans = [
  {
    title: 'Startup',
    features: [
      { text: 'FB-Insta Page Setup', included: true },
      { text: 'Facebook Ad', included: true },
      { text: 'Instagram Ad', included: true },
      { text: 'Conversation Ad', included: true },
      { text: 'Lead Generation', included: true },
      { text: 'Wp inquiry Ad Setup', included: true },
      { text: '8 Post Design', included: true },

    ],
    price: '10000',
    imgSrc: startup, 
  },
  {
    title: 'Growth',
    features: [
      { text: 'FB-Insta  Page Setup', included: true },
      { text: 'Facebook Ad', included: true },
      { text: 'Instagram Ad', included: true },
      { text: 'Socail Media Management', included: true },
      { text: 'Lead Generation', included: true },
      { text: 'Wp inquiry Ad Setup', included: true },
      { text: '10 Post Design', included: true },
      { text: '2 Reel Creation', included: true },

    ],
    price: '15000',
    imgSrc: growth,
  },
  {
    title: 'Enterprise',
    features: [
      { text: 'FB-Insta Page Setup', included: true },
      { text: 'Facebook Ad', included: true },
      { text: 'Instagram Ad', included: true },
      { text: 'Socail Media Management', included: true },
      { text: 'Lead Generation', included: true },
      { text: '10 Post Design', included: true },
      { text: '6 Reel Creation', included: true },
      { text: 'Sales Campaign', included: true },
      { text: 'Pixel Setup', included: true },
    ],
    price: '25000',
    imgSrc: premium, 
  },
];

const Pricing = () => {
  return (
    <>
    <h1 className="headingtxt">Pricing</h1>
    <div className="pricing">
      {plans.map((plan, index) => (
        <div className="card-wrapper" key={index}>
          <div className="card-header">
            <img src={plan.imgSrc} alt={`${plan.title} Plan`} />
            <h2>{plan.title}</h2>
          </div>
          <div className="card-detail">
            {plan.features.map((feature, index) => (
              <p key={index}>
                
                <span>

                {feature.included ? '✓ ' : '✕ '}
                &nbsp;
                </span>
                {feature.text}
              </p>
            ))}
          </div>
          <div className="card-price">
            <p>{plan.price}₹/month</p>
          </div>
          <button className="card-button">
            <Link to="https://wa.me/918490084721?text=Hello%20Linkview%20Digital%20Hub!" target='_blank'>
            Buy Now</Link>
            </button>
        </div>
      ))}
    </div>
    </>
  );
};

export default Pricing;
