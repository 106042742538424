

import React, { useEffect, useRef } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { FaStaylinked } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { TbBrandAws } from "react-icons/tb";
import "./service.css";

import { Link } from "react-router-dom";
import sideImage1 from "../../images/13.png";
import sideImage2 from "../../images/14.png";
import sideImage3 from "../../images/15.png";

import { TbTargetArrow } from "react-icons/tb";
import { RiHandCoinLine } from "react-icons/ri";
import { GiCash } from "react-icons/gi";

import { AiOutlineGlobal } from "react-icons/ai";
import { TbTarget } from "react-icons/tb";
import { MdConnectWithoutContact } from "react-icons/md";

import { HiOutlineSpeakerphone } from "react-icons/hi";
import { BiSolidLike } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";

const Branding = () => {
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);

  const checkScroll = () => {
    [boxRef1, boxRef2, boxRef3].forEach((ref) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          ref.current.classList.add("in-view");
        } else {
          ref.current.classList.remove("in-view");
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <>
      <Navbar />
     <div className="main-container">

      <div className="service-pages">

        <section id="home" className="home">
          <h1>Services &gt;</h1>
          <h2>Branding Service</h2>
          <div className="wave wave1"></div>
          <div className="wave wave2"></div>
          <div className="wave wave3"></div>
        </section>

        <h1 className="service-text">We Provide</h1>
        <section className="service-section">
          <div className="row">
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaStaylinked />
                  </i>
                </div>
                 <br/>
                <h3>Logo Design</h3>
                <p>
                Craft a lasting first impression with our Logo Design service. Elevate your brand identity with a unique, memorable logo that captures your essence and sets you apart.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <IoLogoInstagram />
                  </i>
                </div>
                 <br/>
                <h3>Social Media Branding</h3>
                <p>
                Amplify your online presence with our Social Media Branding service. Create a cohesive, impactful brand image across platforms to engage audiences and build loyalty.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <TbBrandAws />
                  </i>
                </div>
                 <br/>
                <h3>Personal Branding</h3>
                <p>
                Build a powerful, distinctive personal brand with our services. Stand out professionally, attract new opportunities, and advance your career. Let's collaboratively shape your unique success story.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
          </div>

          <div ref={boxRef1} className="content-box">
              <div className="side-image">
                <img src={sideImage1} alt="Service" />
              </div>

              <div className="side-text">
                <h1>

                  How can your Logo Design service impact my brand's identity and recognition?

                </h1><br />
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <TbTargetArrow />
                  </i>
                  <p className="p-text">
                    <b>Distinctive Brand Representation:</b>
                    <br />
                    Our logo service crafts a unique symbol embodying your values and vision, making your brand instantly recognizable and distinct from competitors.
                  </p>
                </div>
                <br />
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <RiHandCoinLine />
                  </i>
                  <p className="p-text">
                    <b>Visual Consistency Across Platforms:</b>
                    <br />


                    We ensure your logo's impact and recognition across all platforms, optimizing designs for digital and print to maintain your brand's visual consistency.
                  </p>
                </div><br />{" "}
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <GiCash />
                  </i>
                  <p className="p-text">
                    <b>
                      Scalability for Versatile Usage:</b>
                    <br />
                    Designed for scalability, our logos maintain clarity and appeal on any scale, from billboards to business cards, ensuring versatility in branding applications.
                  </p>
                </div>
              </div>
            </div>
            <div
              ref={boxRef2}
              className="content-box"
              style={{ background: "whitesmoke", borderRadius: "4%" }}
            >
              <div className="side-image">
                <img src={sideImage2} alt="Service" />
              </div>

              <div className="side-text">
                <h1>How can Personal Branding services elevate my professional presence and opportunities?</h1><br />
                <div
                  className="icon-paragraph-container"
                  style={{ color: "black" }}
                >
                  <i className="sicons left-icon">
                    <AiOutlineGlobal />
                  </i>
                  <p className="p-text">
                    <b>Strategic Brand Development:</b>
                    <br />
                    Our services develop a strategic personal brand aligned with your goals, highlighting your unique value and expertise to set you apart in your industry.
                  </p>
                </div>
                <br />
                <div
                  className="icon-paragraph-container"
                  style={{ color: "black" }}
                >
                  <i className="sicons left-icon">
                    <TbTarget />
                  </i>
                  <p className="p-text">
                    <b>Consistent Visual Identity:</b>
                    <br />
                    We create a consistent visual identity for you, with a professional logo and cohesive online profiles, ensuring a polished presence across platforms.


                  </p>

                </div>
                <br />{" "}
                <div
                  className="icon-paragraph-container"
                  style={{ color: "black" }}
                >
                  <i className="sicons left-icon">
                    <MdConnectWithoutContact />
                  </i>
                  <p className="p-text">
                    <b>
                      Online Reputation Enhancement:
                    </b>
                    <br />
                    Our personal branding enhances your online reputation, employing strategies to highlight your professional achievements and stand out digitally.
                  </p>
                </div>
              </div>
            </div>


            <div ref={boxRef3} className="content-box">
              <div className="side-image">
                <img src={sideImage3} alt="Service" />
              </div>

              <div className="side-text">
                <h1>

                  What benefits does your Social Media Branding service offer for my business's online image?
                </h1><br />
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <HiOutlineSpeakerphone />
                  </i>
                  <p className="p-text">
                    <b>Distinctive Brand Representation:</b>
                    <br />
                    Our logo service creates a unique symbol reflecting your values and vision, offering instant brand recognition and distinction from competitors.

                  </p>
                </div>
                <br />
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <BiSolidLike />
                  </i>
                  <p className="p-text">
                    <b>
                      Visual Consistency Across Platforms:
                    </b>
                    <br />
                    We ensure your logo maintains visual impact and recognition across platforms, optimizing for both digital and print to unify your brand's presence.
                  </p>
                </div>{" "}<br />
                <div className="icon-paragraph-container">
                  <i className="sicons left-icon">
                    <IoIosPeople />
                  </i>
                  <p className="p-text">
                    <b>Scalability for Versatile Usage:</b>
                    <br />
                    Designed for versatility, our logos scale perfectly from billboards to business cards, maintaining clarity and appeal for any branding need.
                  </p>
                </div>
              </div>
            </div>
        </section>
      </div>
      </div>

      <div className="footer">
        <center>
<Footer/>
</center>
</div>
    </>
  );
};

export default Branding;
