import React from 'react'
import Featurebox from './Featurebox';

import fimage1 from '../../src/images/icon1.svg'
import fimage2 from '../../src/images/icon2.svg'
import fimage3 from '../../src/images/icon3.svg'
import fimage4 from '../../src/images/icon4.svg'
import fimage5 from '../../src/images/icon5.svg'
import fimage6 from '../../src/images/icon6.svg'
import fimage7 from '../../src/images/icon7.svg'
import fimage8 from '../../src/images/icon8.svg'

 function Feature() {
  return (
    <div id='services'>
        <h1 className="headingtxt">Services</h1>
        <h1 style={{backgroundImage: 'linear-gradient(250deg, #e59124, white)', WebkitBackgroundClip: 'text', color: 'transparent'}}>
  Unique technologies & modern approach
</h1>

 
        <div className='a-container'>
            <Featurebox image={fimage1} title="Web Design & Development"/>
            <Featurebox image={fimage2} title="Digital Marketing"/>
            <Featurebox image={fimage3} title="SEO"/>
            <Featurebox image={fimage4} title="Video Editing"/>
        </div>

        <div className='a-container'>
            <Featurebox image={fimage5} title="Branding"/>
            <Featurebox image={fimage6} title="UIUX Design"/>
            <Featurebox image={fimage7} title="Advertiesment"/>
            <Featurebox image={fimage8} title="Product Marketing"/>
        </div>
      

    </div>
  )
}
export default Feature;
