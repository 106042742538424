import React, { useState } from "react";
import "./Contact.css";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [messageError, setMessageError] = useState("");

  const validateFirstName = () => {
    if (!firstName.trim()) {
      setFirstNameError("Please enter your first name");
    } else {
      setFirstNameError("");
    }
  };

  const validateLastName = () => {
    if (!lastName.trim()) {
      setLastNameError("Please enter your last name");
    } else {
      setLastNameError("");
    }
  };

  const validateEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const validateMobile = () => {
    const regex = /^\d{10}$/;
    if (!regex.test(mobile)) {
      setMobileError("Please enter a 10-digit mobile number");
    } else {
      setMobileError("");
    }
  };

  const validateMessage = () => {
    if (!message.trim()) {
      setMessageError("Please enter your message");
    } else {
      setMessageError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFirstName();
    validateLastName();
    validateEmail();
    validateMobile();
    validateMessage();

    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      mobileError ||
      messageError
    ) {
      return;
    }

    // Proceed with form submission
  };

  return (
    <>
      <Navbar />
      <div className="contactUS">
        <div className="title">
          <h2>Get in Touch</h2>
        </div>
        <div className="box">
          <div className="contact form">
            <h3>Send a Message</h3>
            <form
              // onSubmit={handleSubmit}
              action="https://formspree.io/f/xrgnawlb"
              method="POST"
            >
              <div className="formBox">
                <div className="row50">
                  <div
                    className={`inputBox ${
                      firstNameError
                        ? "invalid"
                        : firstName.trim()
                        ? "valid"
                        : ""
                    }`}
                  >
                    <span>First Name</span>
                    <input
                      type="text"
                      name="fname"
                      placeholder="John"
                      value={firstName}
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                      onBlur={() => {
                        validateFirstName();
                        setFirstNameError(
                          firstName ? "" : "Please enter your first name"
                        );
                      }}
                    />
                    {firstNameError && (
                      <div className="error">{firstNameError}</div>
                    )}
                  </div>
                  <div
                    className={`inputBox ${
                      lastNameError ? "invalid" : lastName.trim() ? "valid" : ""
                    }`}
                  >
                    <span>Last Name</span>
                    <input
                      type="text"
                      name="lname"
                      required
                      placeholder="Doe"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      onBlur={() => {
                        validateLastName();
                        setLastNameError(
                          lastName ? "" : "Please enter your last name"
                        );
                      }}
                    />
                    {lastNameError && (
                      <div className="error">{lastNameError}</div>
                    )}
                  </div>
                </div>
                <div className="row50">
                  <div
                    className={`inputBox ${
                      emailError ? "invalid" : email.trim() ? "valid" : ""
                    }`}
                  >
                    <span>Email</span>
                    <input
                      type="text"
                      required
                      placeholder="johndoe@Email.com"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => {
                        validateEmail();
                        setEmailError(
                          email ? "" : "Please enter a valid email address"
                        );
                      }}
                    />
                    {emailError && <div className="error">{emailError}</div>}
                  </div>
                  <div
                    className={`inputBox ${
                      mobileError ? "invalid" : mobile.trim() ? "valid" : ""
                    }`}
                  >
                    <span>Mobile</span>
                    <input
                      type="text"
                      name="contact"
                      required
                      maxLength="10"
                      minLength="10"
                      title="enter valid 10 digit mobile number"
                      placeholder="+91 978 347 1234"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      onBlur={() => {
                        validateMobile();
                        setMobileError(
                          mobile ? "" : "Please enter a 10-digit mobile number"
                        );
                      }}
                    />
                    {mobileError && <div className="error">{mobileError}</div>}
                  </div>
                </div>
                <div className="row100">
                  <div
                    className={`inputBox ${
                      messageError ? "invalid" : message.trim() ? "valid" : ""
                    }`}
                  >
                    <span>Message</span>
                    <textarea
                      placeholder="Write your message here......"
                      value={message}
                      name="msg"
                      onChange={(e) => setMessage(e.target.value)}
                      onBlur={() => {
                        validateMessage();
                        setMessageError(
                          message ? "" : "Please enter your message"
                        );
                      }}
                    ></textarea>
                    {messageError && (
                      <div className="error">{messageError}</div>
                    )}
                  </div>
                </div>
                <div className="row100">
                  <div className="inputBox">
                    <input type="submit" value="Send" target="_self"/>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="contact info">
            <h3>Contact Info</h3>
            <div className="infoBox">
              <div>
                <span>
                  <Link
                    to="https://maps.app.goo.gl/8P7p3zJj9UXtbNCq7"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    <CiLocationOn />
                  </Link>
                </span>
                <p style={{ color: "white" }}>
                  {" "}
                  D-7, Bhagvan Nagar Society, Opp. Vikas Shopper,
Sarthana Jagatnaka, Surat
                </p>
              </div>
              <div>
                <span>
                  <Link
                    to="mailto:contact.linkview@gmail.com?subject=Inquiry&body=Hi%20Linkview%20Digital%20Hub"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    <MdOutlineMarkEmailRead />
                  </Link>
                </span>
                <p style={{ color: "white" }}>digital@linkview.in</p>
              </div>
              <div>
                <span>
                  <Link
                    to="tel:+918980639900"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    <IoCallOutline />
                  </Link>
                </span>
                <p style={{ color: "white" }}>+91 8980 639 900</p>
              </div>
              <ul className="sci">
                <li>
                  <Link
                    to="https://www.facebook.com/linkview.digitalmarketing"
                    target="_blank"
                    
                  >
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://in.linkedin.com/company/linkviewdigital"
                    target="_blank"
                    
                  >
                    {" "}
                    <FaLinkedinIn />
                  </Link>
                </li>{" "}
                <li>
                  {" "}
                  <Link
                    to="https://www.instagram.com/linkview.marketing"
                    target="_blank"
                    
                  >
                    {" "}
                    <FaInstagram />{" "}
                  </Link>{" "}
                </li>{" "}
                <li>
                  {" "}
                  <Link
                    to="mailto:digital@linkview.in?subject=Inquiry&body=Hi%20Linkview%20Digital%20Hub"
                    target="_blank"
                    
                  >
                    <MdOutlineMarkEmailRead />
                  </Link>{" "}
                </li>
              </ul>{" "}
            </div>{" "}
          </div>{" "}
          <div className="contact map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d781.8534621853872!2d72.90148820517807!3d21.22618522708436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f7ff5e9ac71%3A0xcd995b78bfa47e96!2sLinkview%20Marketing%20-%20Digital%20Hub!5e0!3m2!1sen!2sin!4v1705583143813!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
