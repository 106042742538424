import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import image11 from "../images/smback.jpg";
import "./Privacy.css"


const Privacy = () => {
  return (
    <div className="App">
      <Navbar />

      <div classNameName="container">
        <div>
          <img
            src={image11}
            style={{ opacity: "0.5" }}
            alt="Service Background"
            width="1700px"
            height="700px"
          />
          <div className="overlay-text">
            <h1
              className="animated-text"
              style={{ textAlign: "center", color: "white" }}
            >
              Privacy Policy
            </h1>
          </div>
        </div>
      </div>

      <div className="privacy-policy">
        <div class="section">
          <div class="container">
            <div class="title">
              <h1>Privacy Policy</h1>
            </div>

            <h3>
              Linkview digital hub operates the www.Linkview.in website. This
              page informs you of our policies regarding the collection, use,
              and disclosure of personal data when you use our Service and the
              choices you have associated with that data. We use your data to
              provide and improve the Service. By using the Service, you agree
              to the collection and use of information in accordance with this
              policy.
              <br />
              <br />
              Information Collection And Use :<br />
              <div className="p">
                {" "}
                collect several different types of information for various
                purposes to provide and improve our Service to you.
              </div>
              <br />
              <br />
              Types of Data Collected :<br />
              <br />
              Personal Data :
              <div className="p">
                {" "}
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you Personal Data.
                <br />
                <br />
              </div>
              Usage Data :<br />
              <div className="p">
                {" "}
                We may also collect information on how the Service is accessed
                and used Usage Data.
                <br />
                <br />
              </div>
              Tracking & Cookies Data :
              <br />
              <div className="p">
                We use cookies and similar tracking technologies to track the
                activity on our Service and hold certain information.
                <br />
                <br />
              </div>
              Use of Data:
              <br />
              <div className="p">
                Linkview digital hub uses the collected data for various
                purposes, such as to provide and maintain the Service, notify
                you about changes to our Service, allow you to participate in
                interactive features of our Service when you choose to do so,
                provide customer care and support, and monitor the usage of the
                Service.
              </div>
              <br />
              Transfer Of Data :
              <br />
              <div className="p">
                {" "}
                Your information, including Personal Data, may be transferred to
                — and maintained on — computers located outside of your state,
                province, country, or other governmental jurisdiction where the
                data protection laws may differ from those from your
                jurisdiction.
              </div>
              <br />
              Disclosure Of Data :
              <br />
              <div className="p">
                {" "}
                We may disclose your Personal Data in the good faith belief that
                such action is necessary to comply with a legal obligation,
                protect and defend our rights or property, prevent or
                investigate possible wrongdoing in connection with the Service,
                protect the personal safety of users of the Service or the
                public, and protect against legal liability..
              </div>
              <br />
              Security Of Data :
              <div className="p">
                The security of your data is important to us, but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure.
              </div>
              <br />
              Service Providers :
              <br />
              <div className="p">
                We may employ third-party companies and individuals to
                facilitate our Service, provide the Service on our behalf,
                perform Service-related services, or assist us in analyzing how
                our Service is used.
              </div>
              <br />
              Links To Other Sites :
              <br />
              <div className="p">
                {" "}
                Our Service may contain links to other sites that are not
                operated by us. If you click on a third-party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </div>
              <br />
              Children's Privacy :
              <br />
              <div className="p">
                {" "}
                Our Service does not address anyone under the age of 18
                Children..
              </div>
              <br />
              Changes To This Privacy Policy :
              <br />
              <div className="p">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </div>
              <br />
              <h1>Contact Us :</h1>
              If you have any questions about this Privacy Policy, please
              contact us:
              <br />
              <br />
              By email: <div className="p">digital@linkview.in
</div>
              <br />
              By visiting this page on our website:
              <div className="p"> www.linkview.in</div>
              <br />
              By phone number: <div className="p">+91 898 063 9900</div>
              <br />
              <br />
              By mail
              <div className="p">
                : B-7, Bhagvan Nagar, Opp.varj Bus Parking,
                <br />
                Vikas Shopper, Sarthana Jagatnaka, Surat.
              </div>
              <br />
              <br />
            </h3>

            <h2> - LINKVIEW DIGITAL HUB -</h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy