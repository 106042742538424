// import React, { useState } from "react";
// // import logo from "../../src/images/logo.png";
// import logo from "../../src/images/main.png";
// // import { Link } from "react-scroll";
// import { Link } from "react-router-dom";
// import { MdKeyboardArrowDown } from "react-icons/md";


// function Navbar() {
//   const [nav, setNav] = useState(false);

//   const changeBackground = () => {
//     if (window.scrollY >= 50) {
//       setNav(true);
//     } else {
//       setNav(false);
//     }
//   };
//   window.addEventListener("scroll", changeBackground);

//   return (
//     <nav className={nav ? "nav active" : "nav"}>
//       <Link to="main" className="logo">
//         <img src={logo} alt="img logo" />
//       </Link>
//       <input className="menu-btn" type="checkbox" id="menu-btn" />
//       <label className="menu-icon" for="menu-icon">
//         <span className="nav-icon"></span>
//       </label>
//       <ul className="menu">
//         <li>
//           <Link to="/" smooth={true} duration={1000}>Home</Link>
//         </li>
//         <li>
//           <Link to="/services" smooth={true} duration={1000}>Services <span><MdKeyboardArrowDown/></span></Link>
//         </li>
//         <li>
//           <Link to="/ourwork" smooth={true} duration={1000}>Our Work</Link>
//         </li>
//         <li>
//           <Link to="/about" smooth={true} duration={1000}>About</Link>
//         </li>
//         <li>
//           <Link to="/contact" smooth={true} duration={1000}>Contact</Link>
//         </li>
//       </ul>
//     </nav>
//   );
// }
// export default Navbar;



// import React, { useState } from "react";
// // import logo from "../../src/images/logo.png";
// import logo from "../../src/images/main.png";
// // import { Link } from "react-scroll";
// import { Link } from "react-router-dom";
// import { MdKeyboardArrowDown } from "react-icons/md";


// function Navbar() {
//   const [nav, setNav] = useState(false);
//   const [showDropdown, setShowDropdown] = useState(false);

  
//   const changeBackground = () => {
//     if (window.scrollY >= 50) {
//       setNav(true);
//     } else {
//       setNav(false);
//     }
//   };

//   const handleMouseEnter = () => {
//     setShowDropdown(true);
//   };

//   const handleMouseLeave = () => {
//     setShowDropdown(false);
//   };

//   window.addEventListener("scroll", changeBackground);

//   return (
//     <nav className={nav ? "nav active" : "nav"}>
//       <Link to="/" className="logo">
//         <img src={logo} alt="img logo" />
//       </Link>
//       <input className="menu-btn" type="checkbox" id="menu-btn" />
//       <label className="menu-icon" for="menu-icon">
//         <span className="nav-icon"></span>
//       </label>
//       <ul className="menu">
//         <li>
//           <Link to="/" smooth={true} duration={1000}>Home</Link>
//         </li>
//         <li onMouseEnter={handleMouseEnter}
//           onMouseLeave={handleMouseLeave}>
//         <span style={{color:'white'}}> <Link to="/services" smooth={true} duration={1000}>Services <span><MdKeyboardArrowDown/></span></Link></span>
//         {showDropdown && (
//             <div className="dropdown-content">
//               <Link to="/webdesign" >Website Design </Link>
//               <Link to="/digitalmarkeing" >Digital Marketing</Link>
//               <Link to="/seo">Search Engine Optimization</Link>
//               <Link to="/videoediting" >Video editing</Link>
//               <Link to="/graphicdesign" >Graphic design</Link>
//               <Link to="/branding" >Branding</Link>
              
//             </div>
//           )}
//         </li>
//         <li>
//           <Link to="/blog" smooth={true} duration={1000}>Blog</Link>
//         </li>
//         <li>
//           <Link to="/about" smooth={true} duration={1000}>About</Link>
//         </li>
//         <li>
//           <Link to="/contact" smooth={true} duration={1000}>Contact</Link>
//         </li>
//       </ul>
//     </nav>
//   );
// }
// export default Navbar;




// Navbar.jsx

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { FaBars, FaTimes } from 'react-icons/fa';
// import './Navbar.css'; // Make sure to import the CSS file

// const Navbar = () => {
//   const [navbar, setNavbar] = useState(false);
//   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

//   const changeBackground = () => {
//     if (window.scrollY >= 80) {
//       setNavbar(true);
//     } else {
//       setNavbar(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', changeBackground);
//     return () => window.removeEventListener('scroll', changeBackground);
//   }, []);

//   return (
//     <nav className={`navbar ${navbar ? 'navbar-active' : ''}`}>
//       <div className="navbar-container">
//         <Link to="/" className="navbar-logo" onClick={() => setMobileMenuOpen(false)}>
//           LOGO
//         </Link>
//         <div className="menu-icon" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
//           {mobileMenuOpen ? <FaTimes /> : <FaBars />}
//         </div>
//         <ul className={`nav-menu ${mobileMenuOpen ? 'active' : ''}`}>
//           <li className="nav-item">
//             <Link to="/" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
//               Home
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
//               Home
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
//               Home
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
//               Home
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link to="/" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
//               Home
//             </Link>
//           </li>
//           {/* Add additional nav items here */}
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaCaretDown } from 'react-icons/fa';
import './Navbar.css';
import logo from "../images/main.svg"

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click is outside the dropdown and if dropdown is open
      if (dropdownOpen && !event.target.closest('.nav-item')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    window.addEventListener('scroll', changeBackground);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('scroll', changeBackground);
    };
  }, [dropdownOpen]);

  const handleDropdown = (event) => {
    event.stopPropagation(); // Prevent event from propagating to the document click listener
    setDropdownOpen(!dropdownOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setDropdownOpen(false); // Ensure the dropdown is also closed when any menu item is clicked
  };

  return (
    <nav className={`navbar ${navbar ? 'navbar-active' : ''}`}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
        <img src={logo} alt="Company Logo" className="navbar-logo-image" style={{marginTop:'0px'}}/>
        </Link>
        <div className="menu-icon" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          {mobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-menu ${mobileMenuOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item" onClick={(e) => e.stopPropagation()}>
            <div className="nav-links" onClick={handleDropdown}>
            
              Services <FaCaretDown />
             
            </div>
            {dropdownOpen && (
              <ul className="dropdown-content">
                <li><Link to="/digitalmarketing" onClick={closeMobileMenu}>Digital Marketing</Link></li>
                <li><Link to="/seo" onClick={closeMobileMenu}>Search Engine Optimization</Link></li>
                <li><Link to="/videoediting" onClick={closeMobileMenu}>Video Editing</Link></li>
                <li><Link to="/graphicdesign" onClick={closeMobileMenu}>Graphic Design</Link></li>
                <li><Link to="/branding" onClick={closeMobileMenu}>Branding</Link></li>
                <li><Link to="/webdesign" onClick={closeMobileMenu}>Website Design</Link></li>
              </ul>
            )}
          </li>
          <li className="nav-item">
            <Link to="/blog" className="nav-links" onClick={closeMobileMenu}>
              Blog
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;



