// import React from 'react'

// export default function Contact() {
//   return (
//     <div id='contact'>
//         <h1>CONTACT US</h1>
//         <form action='https://formspree.io/f/xjvnyrao' method='POST'>
//             <input type='text' placeholder='Full Name' name='fullname' autoComplete='off' required />   
//             <input type='email' placeholder='Type Your E-mail' name='email'autoComplete='off' required />    
//             <input type='text' placeholder='Enter Mobile Number' name='mobile' autoComplete='off' required />    
//            <textarea placeholder='How Can We Help You?' name='message' autoComplete='off' ></textarea>
//            <input type='submit' value='send'/>
//             </form>
//     </div>
//   )
// }


import React, { useState } from 'react';

export default function Contact() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');

  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const phoneRegex = /^\d{10}$/;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
  };

  

  return (
    <div id='contact'>
      <h1>Contact Us</h1>
      <form action='https://formspree.io/f/xrgnawlb' method='POST'>
        <input
          type='text'
          placeholder='Full Name'
          name='fullname'
          autoComplete='off'
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
        <input
          type='email'
          placeholder='Type Your E-mail'
          name='email'
          autoComplete='off'
          value={email}
          onChange={handleEmailChange}
          required
        />
        {email && !emailRegex.test(email) && <span className="error-message">Enter valid email </span>}
        <input
          type='text'
          placeholder='Enter Mobile Number'
          name='mobile'
          autoComplete='off'
          value={mobile}
          onChange={handleMobileChange}
          required
        />
        {mobile && !phoneRegex.test(mobile) && <span className="error-message">Enter Valid mobile number</span>}
        <textarea
          placeholder='How Can We Help You?'
          name='message'
          autoComplete='off'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <input type='submit' value='send' target="_self"/>
      </form>


      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d781.8534621853872!2d72.90148820517807!3d21.22618522708436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f7ff5e9ac71%3A0xcd995b78bfa47e96!2sLinkview%20Marketing%20-%20Digital%20Hub!5e0!3m2!1sen!2sin!4v1705583143813!5m2!1sen!2sin"
          width='100%'
          height='500px'
          style={{ border: 0, boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)', borderRadius: '15px' }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>


    </div>
  );
}
