// import logo from './logo.svg';
import './App.css';
import Feature from './Components/Feature';
import Header from './Components/Header';
import Navbar from './Components/Navbar';

import React from 'react';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Pricing from './Components/Pricing';
import Ourclient from './Components/Ourclient';
import Counter from './Components/Counter';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Header/>
      <Feature/>
   
      <About/>

      <Counter/>

      <Pricing/>
      
      <Ourclient/>

      <Contact/>

      <Footer/>

      
    </div>
  );
}

export default App;
