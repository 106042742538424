// import '../App.css';
// import React from 'react';
// import Navbar from '../Components/Navbar';
// import Footer from '../Components/Footer';
// import image11 from "../images/smback.jpg"; 

// const Services = () => {
//   return (
//     <div className="App">
//       <Navbar />
//       <div className="container">
//       <div style={{ position: 'relative', marginTop: '100px' }}>
//         <img src={image11} style={{opacity:'0.5'}} alt="Service" width="1700px" height="700px" />
//         <div className="overlay-text">
//           <h1 className="animated-text" style={{textAlign:'center',color:'white'}}>Our Services</h1>
//         </div>
//       </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default Services;

// import React from 'react';
// import Navbar from '../Components/Navbar';
// import Footer from '../Components/Footer';
// import image11 from "../images/smback.jpg";
// import sideImage from "../images/smback.jpg"; // Replace with your image path

// const Services = () => {
//   return (
//     <div className="App">
//       <Navbar />
//       <div className="container">
//         <div style={{ position: 'relative', marginTop: '100px' }}>
//           <img src={image11} style={{ opacity: '0.5' }} alt="Service Background" width="1700px" height="700px" />
//           <div className="overlay-text">
//             <h1 className="animated-text" style={{ textAlign: 'center', color: 'white' }}>Our Services</h1>
//           </div>
//         </div>

//         {/* Box containing Image and Text */}
//         <div className="content-box">
//           <div className="side-image">
//             <img src={sideImage} alt="Service" />
//           </div>
//           <div className="side-text">
//             <p>Here is a detailed description of our service...</p>
//           </div>
//         </div>

//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default Services;
// import React from 'react';
// import Navbar from '../Components/Navbar';
// import Footer from '../Components/Footer';
// import image11 from "../images/smback.jpg";
// import sideImage from "../images/vv.jpg"; // Replace with your image path

// const Services = () => {
//   return (
//     <div className="App">
//       <Navbar />
//       <div className="container">
//         <div style={{ position: 'relative', marginTop: '100px' }}>
//           <img src={image11} style={{ opacity: '0.5' }} alt="Service Background" width="1700px" height="700px" />
//           <div className="overlay-text">
//             <h1 className="animated-text" style={{ textAlign: 'center', color: 'white' }}>Our Services</h1>
//           </div>
//         </div>

//         {/* Box containing Image and Text */}
//         <div className="content-box">
//           <div className="side-image">
//             <img src={sideImage} alt="Service" />
//           </div>
//           <div className="side-text">
//             <p>Here is a detailed description of our service...</p>
//           </div>
//         </div>

//         <div className="content-box">
          
//           <div className="side-text">
//             <p>Here is a detailed description of our service...</p>
//           </div>

//           <div className="side-image">
//             <img src={sideImage} alt="Service" />
//           </div>
//         </div>

//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default Services;



// import React, { useEffect, useRef } from 'react';
// import Navbar from '../Components/Navbar';
// import Footer from '../Components/Footer';
// import image11 from "../images/smback.jpg";
// import sideImage from "../images/smback.jpg"; // Replace with your image path

// const Services = () => {
//   const boxRef1 = useRef(null);
//   const boxRef2 = useRef(null);
//   const boxRef3 = useRef(null);

//   const checkScroll = () => {
//     [boxRef1, boxRef2, boxRef3].forEach(ref => {
//       if (ref.current) {
//         const rect = ref.current.getBoundingClientRect();
//         if (rect.top <= window.innerHeight) {
//           ref.current.classList.add("in-view");
//         } else {
//           ref.current.classList.remove("in-view");
//         }
//       }
//     });
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', checkScroll);
//     return () => window.removeEventListener('scroll', checkScroll);
//   }, []);

//   return (
//     <div className="App">
//       <Navbar />
//       <div className="container">
//         <div style={{ position: 'relative', marginTop: '100px' }}>
//           <img src={image11} style={{ opacity: '0.5' }} alt="Service Background" width="1700px" height="700px" />
//           <div className="overlay-text">
//             <h1 className="animated-text" style={{ textAlign: 'center', color: 'white' }}>Our Services</h1>
//           </div>
//         </div>

//         <div ref={boxRef1} className="content-box">
//           <div className="side-image">
//             <img src={sideImage} alt="Service" />
//           </div>
//           <div className="side-text">
//             <p>Here is a detailed description of our service...</p>
//           </div>
//         </div>

//         <div ref={boxRef2} className="content-box">
//           <div className="side-image">
//             <img src={sideImage} alt="Service" />
//           </div>
//           <div className="side-text">
//             <p>Another detailed description of our service...</p>
//           </div>
//         </div>

//         <div ref={boxRef3} className="content-box">
//           <div className="side-image">
//             <img src={sideImage} alt="Service" />
//           </div>
//           <div className="side-text">
//             <p>More about our service...</p>
//           </div>
//         </div>

//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default Services;

 
import React, { useEffect, useRef } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import image11 from "../images/smback.jpg";
import sideImage1 from "../images/sideimg1.png"; 
import sideImage2 from "../images/sideimg2.png"; 
import sideImage3 from "../images/sideimg3.png"; 
import sideImage4 from "../images/sideimg4.png"; 
import sideImage5 from "../images/sideimg5.png"; 



const Services = () => {
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);
  const boxRef4 = useRef(null);
  const boxRef5 = useRef(null);

  const checkScroll = () => {
    [boxRef1, boxRef2, boxRef3, boxRef4, boxRef5].forEach(ref => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          ref.current.classList.add("in-view");
        } else {
          ref.current.classList.remove("in-view");
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  return (
    <div className="App">
      <Navbar />
      
      <div className="container">
        <div className='service-header'>
          <img src={image11} style={{ opacity: '0.5' }} alt="Service Background" width="1700px" height="700px" />
          <div className="overlay-text">
            <h1 className="animated-text" style={{ textAlign: 'center', color: 'white' }}>Our Services</h1>
          </div>
        </div>
        <div class="wave wave1"></div>
        <div class="wave wave2"></div>
        <div class="wave wave3"></div>
        {/* </section> */}

        <div ref={boxRef1} className="content-box">
          <div className="side-image">
            <img src={sideImage1} alt="Service" />
          </div>
          <div className="side-text">
            <h1>Digital marketing</h1>
            <p>Elevate your brand's online presence with our cutting-edge digital marketing strategies tailored to your unique business needs.</p>
          </div>
        </div>

        <div ref={boxRef2} className="content-box">
          
          <div className="side-text">
            <h1>SEO</h1>
            <p>Boost your website's visibility and drive organic traffic with our expert SEO services, focusing on performance and results.</p>
          </div>
          <div className="side-image">
            <img src={sideImage2} alt="Service" />
          </div>
        </div>

        <div ref={boxRef3} className="content-box">
          <div className="side-image">
            <img src={sideImage3} alt="Service" />
          </div>
          <div className="side-text">
            <h1>Graphic Design</h1>
            <p>Transform your ideas into stunning visuals with our graphic design services, where creativity meets professionalism.</p>
          </div>
        </div>

        <div ref={boxRef4} className="content-box">
          
        <div className="side-text">
          <h1>Video Editing</h1>
            <p>Bring your stories to life with our professional video editing services, blending art and technology to create compelling content.</p>
          </div>
          <div className="side-image">
            <img src={sideImage4} alt="Service" />
          </div>
         
        </div>

        <div ref={boxRef5} className="content-box">
          <div className="side-image">
            <img src={sideImage5} alt="Service" />
          </div>
          <div className="side-text">
            <h1>Web Design & Development</h1>
            <p>Crafting bespoke, user-friendly websites with our web development services to enhance your digital footprint and engage your audience.</p>
          </div>
        </div>
        
      </div>
      <Footer />
    </div>
  );
}

export default Services;
