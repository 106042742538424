

import React, { useEffect, useRef } from "react";
import "./service.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { FaWordpress } from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import { FaAmazon } from "react-icons/fa";
import { Link } from "react-router-dom";
import sideImage1 from "../../images/sideimg1.png";
import sideImage2 from "../../images/sideimg2.png";
import sideImage3 from "../../images/6psd.png";

const Websitedesign = () => {
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);

  const checkScroll = () => {
    [boxRef1, boxRef2, boxRef3].forEach((ref) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          ref.current.classList.add("in-view");
        } else {
          ref.current.classList.remove("in-view");
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <>
     <Navbar />
     <div className="main-container">

      <div className="service-pages">
       

        <section id="home" className="home">
          <h1>Services &gt;</h1>
          <h2>Web Design & Development</h2>
          <div className="wave wave1"></div>
          <div className="wave wave2"></div>
          <div className="wave wave3"></div>
        </section>

        <h1 className="service-text">We Provide</h1>
        <section className="service-section">
          <div className="row">
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <MdWeb />
                  </i>
                </div>
                <br/>
                <h3>Responsive Website</h3>
                <p>
                  Capture every user, on every device. Our responsive website
                  design ensures your site delivers a flawless experience across
                  all platforms, making your brand stand out in the digital
                  landscape.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaAmazon />
                  </i>
                </div>
                <br/>
                <h3>E-commerce Website</h3>
                <p>
                Drive your e-commerce business forward with our specialized website development. From Shopify builders to custom solutions, we create online stores that sell more than just products – they sell experiences.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaWordpress />
                  </i>
                </div>
                <br/>
                <h3>Wordpress Service</h3>
                <p>
                  Unleash the full potential of your online presence with our
                  WordPress website design services. Expert WordPress website
                  designers craft bespoke solutions, turning your ideas into a
                  dynamic digital reality.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
          </div>

          <div ref={boxRef1} className="content-box">
            <div className="side-image">
              <img src={sideImage1} alt="Service" />
            </div>
            <div className="side-text">
              <h1>
                Why is having a responsive website essential for businesses
                today?
              </h1>
              <p>
                <ul>
                  <li>&#10687;	Increased Mobile Traffic</li>
                  <li>&#10687;	Improved User Experience</li>
                  <li>&#10687;	Better SEO Ranking</li>
                  <li>&#10687;	Lower Maintenance Costs</li>
                  <li>&#10687;	Higher Conversion Rates</li>
                  <li>&#10687;	Social Media Impact</li>
                  <li>&#10687;	Faster Webpages</li>
                  <li>&#10687;	Enhanced Social Sharing</li>
                  <li>&#10687;	Increased Local Search Visibility</li>
                  <li>&#10687;	Professional Brand Image</li>
                  <li>&#10687;	And many more.....</li>
              
                </ul>
              </p>
            </div>
          </div>

          <div ref={boxRef2} className="content-box">
            <div className="side-text">
              <h1>Why is an e-commerce website crucial for retail business success today?</h1>
              <p>
              <ul>
                  <li>&#10687;	Broader Market Reach</li>
                  <li>&#10687;	Cost Reduction</li>
                  <li>&#10687;	Convenience for Customers</li>
                  <li>&#10687;	Personalized Shopping Experience</li>
                  <li>&#10687;	Scalability</li>
                  <li>&#10687;	Increased Sales Opportunities</li>
                  <li>&#10687;	Direct Customer Interaction</li>
                  <li>&#10687;	Faster Response to Market Trends</li>
                  <li>&#10687;	Global Brand Recognition</li>
                  <li>&#10687;	Improved Customer Insights</li>
                  <li>&#10687;	And many more.....</li>
                </ul>
                </p>
            </div>
            <div className="side-image">
              <img src={sideImage2} alt="Service" />
            </div>
          </div>

          <div ref={boxRef3} className="content-box">
            <div className="side-image">
              <img src={sideImage3} alt="Service" />
            </div>
            <div className="side-text">
              <h1>Why is choosing WordPress services beneficial for your website development?</h1>
              <p>
                <ul>
                  <li>&#10687;	User-Friendly CMS</li>
                  <li>&#10687;	Flexibility and Scalability</li>
                  <li>&#10687;	SEO-Friendly</li>
                  <li>&#10687;	Extensive Plugin Ecosystem</li>
                  <li>&#10687;	Responsive Design</li>
                  <li>&#10687;	Strong Community Support</li>
                  <li>&#10687;	Regular Updates</li>
                  <li>&#10687;	Customizable Designs</li>
                  <li>&#10687;	E-commerce Solutions</li>
                  <li>&#10687;	Easy Content Management</li>
                  <li>&#10687;	And many more.....</li>
              
                </ul>
              </p>
            </div>
          </div>
        </section>
      </div>
      </div>

      <div className="footer" >
        <center>
<Footer/>
</center>
</div>
    </>
  );
};

export default Websitedesign;
