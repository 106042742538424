// import React, { useEffect, useRef } from "react";
// import Navbar from "../../Components/Navbar";
// import Footer from "../../Components/Footer";
// import { SiGoogleads } from "react-icons/si";

// import { FaFacebookF } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";


// import { Link } from "react-router-dom";
// import sideImage1 from "../../images/sideimg1.png";
// import sideImage2 from "../../images/sideimg2.png"; 
// import sideImage3 from "../../images/sideimg3.png";

// const Digitalmarketing = () => {
//   const boxRef1 = useRef(null);
//   const boxRef2 = useRef(null);
//   const boxRef3 = useRef(null);

//   const checkScroll = () => {
//     [boxRef1, boxRef2, boxRef3].forEach((ref) => {
//       if (ref.current) {
//         const rect = ref.current.getBoundingClientRect();
//         if (rect.top <= window.innerHeight) {
//           ref.current.classList.add("in-view");
//         } else {
//           ref.current.classList.remove("in-view");
//         }
//       }
//     });
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", checkScroll);
//     return () => window.removeEventListener("scroll", checkScroll);
//   }, []);

//   return (
//     <>
//     <Navbar />
//      <div className="main-container">

//       <div className="service-pages">

//         <section id="home" className="home">
//           <h1>Services &gt;</h1>
//           <h2>Digital Marketing</h2>
//           <div className="wave wave1"></div>
//           <div className="wave wave2"></div>
//           <div className="wave wave3"></div>
//         </section>

//         <h1 className="service-text">We Provide</h1>
//         <section className="service-section">
//           <div className="row">
//             <div className="column">
//               <div className="card">
//                 <div className="icon-wrapper">
//                   <i className="serviceicon">
//                     <SiGoogleads  />
//                   </i>
//                 </div>
//                 <h3>Google Ads Service</h3>
//                 <p>
//                   Google Ads targets users actively searching for related
//                   products or services, offering precise targeting and
//                   cost-effective PPC pricing. It's essential for quickly
//                   attracting qualified leads and enhancing online visibility.
//                 </p>
//                 <Link to="/contact">
//                   <h4>Send Inquiry</h4>
//                 </Link>
//               </div>
//             </div>
//             <div className="column">
//               <div className="card">
//                 <div className="icon-wrapper">
//                   <i className="serviceicon">
//                     <FaInstagram />
//                   </i>
//                 </div>
//                 <h3>Instagram Ads Service</h3>
//                 <p>
//                   Instagram Ads harness the power of visual storytelling and
//                   high engagement rates, making it perfect for brands to
//                   showcase their offerings creatively and connect with their
//                   target audience effectively.
//                 </p>
//                 <Link to="/contact">
//                   <h4>Send Inquiry</h4>
//                 </Link>
//               </div>
//             </div>
//             <div className="column">
//               <div className="card">
//                 <div className="icon-wrapper">
//                   <i className="serviceicon">
//                     <FaFacebookF  />
//                   </i>
//                 </div>
//                 <h3>Facebook Ads Service</h3>
//                 <p>
//                   Facebook Ads leverage advanced targeting and diverse ad
//                   formats to create customized, engaging content, boosting brand
//                   awareness and driving conversions on a platform with a vast
//                   user base.
//                 </p>
//                 <Link to="/contact">
//                   <h4>Send Inquiry</h4>
//                 </Link>
//               </div>
//             </div>
//           </div>

//           <div ref={boxRef1} className="content-box">
//             <div className="side-image">
//               <img src={sideImage1} alt="Service" />
//             </div>
//             <div className="side-text">
//               <h1>
//               Why is Google Ads an essential service for driving targeted traffic?
//               </h1>
//               <p>
//               Google Ads is pivotal for businesses aiming to attract targeted traffic swiftly and effectively. By placing your ads at the top of search engine results, it ensures high visibility to users actively searching for related products or services. The platform offers precise targeting options, including keywords, location, and demographics, enabling you to reach the most relevant audience. Moreover, with pay-per-click (PPC) pricing, you only pay when someone clicks on your ad, making it a cost-effective strategy to boost your online presence and drive qualified leads to your website.
//               </p>
//             </div>
//           </div>

//           <div ref={boxRef2} className="content-box">
//             <div className="side-text">
//               <h1>
//               How does Facebook Ads service enhance brand visibility and engagement?
//               </h1>
//               <p>
//               Facebook Ads stand out as a powerful tool for enhancing brand visibility and engagement due to its vast user base and sophisticated targeting capabilities. This service allows businesses to create highly customized ads that resonate with their target audience based on interests, behaviors, and demographics. Facebook's diverse ad formats, from image and video ads to carousel and stories, enable interactive and engaging content delivery. With extensive analytics, businesses can track ad performance in real-time, optimizing strategies for maximum impact. Facebook Ads not only increase brand awareness but also drive conversions by reaching potential customers on a platform where they spend a significant amount of time.
//               </p>
//             </div>
//             <div className="side-image">
//               <img src={sideImage2} alt="Service" />
//             </div>
//           </div>

//           <div ref={boxRef3} className="content-box">
//             <div className="side-image">
//               <img src={sideImage3} alt="Service" />
//             </div>
//             <div className="side-text">
//               <h1>
//               Why choose Instagram Ads for creative and impactful marketing?
//               </h1>
//               <p>
//               Instagram Ads service is ideal for brands looking to leverage visually compelling and creative marketing. This platform's visually oriented nature is perfect for showcasing products and services through eye-catching images and videos. Instagram’s integration with Facebook Ads provides access to advanced targeting options, ensuring your ads reach your specific audience segments. With features like stories, reels, and IGTV, brands can craft immersive and interactive ad experiences that resonate with their audience. Instagram's high engagement rates make it a valuable channel for not only building brand awareness but also driving direct sales and fostering community engagement, making it an indispensable tool in modern digital marketing strategies.
//               </p>
//             </div>
//           </div>
//         </section>
//       </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Digitalmarketing;





import React, { useEffect, useRef } from "react";
import "./service.css";

import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { SiGoogleads } from "react-icons/si";

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";





import { TbTargetArrow } from "react-icons/tb";
import { RiHandCoinLine } from "react-icons/ri";
import { GiCash } from "react-icons/gi";

import { AiOutlineGlobal } from "react-icons/ai";
import { TbTarget } from "react-icons/tb";
import { MdConnectWithoutContact } from "react-icons/md";

import { HiOutlineSpeakerphone } from "react-icons/hi";
import { BiSolidLike } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";


import { Link } from "react-router-dom";
import sideImage1 from "../../images/sideimg1.png";
import sideImage2 from "../../images/7.png";
import sideImage3 from "../../images/8.png";

const Digitalmarketing = () => {
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);

 

  const checkScroll = () => {
    [boxRef1, boxRef2, boxRef3].forEach((ref) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          ref.current.classList.add("in-view");
        } else {
          ref.current.classList.remove("in-view");
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <>
    <Navbar />
     <div className="main-container">

      <div className="service-pages">
 
        <section id="home" className="home">
          <h1>Services &gt;</h1>
          <h2>Digital Marketing</h2>
          <div className="wave wave1"></div>
          <div className="wave wave2"></div>
          <div className="wave wave3"></div>
        </section>


        <h1 className="service-text">We Provide</h1>
        <section className="service-section">
          <div className="row">
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <SiGoogleads  />
                  </i>
                </div>
                <br/>
                <h3>Google Ads Service</h3>
                <p>
                  Google Ads targets users actively searching for related
                  products or services, offering precise targeting and
                  cost-effective PPC pricing. It's essential for quickly
                  attracting qualified leads and enhancing online visibility.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaInstagram />
                  </i>
                </div>
                <br/>
                <h3>Instagram Ads Service</h3>
                <p>
                  Instagram Ads harness the power of visual storytelling and
                  high engagement rates, making it perfect for brands to
                  showcase their offerings creatively and connect with their
                  target audience effectively.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaFacebookF  />
                  </i>
                </div>
                <br/>
                <h3>Facebook Ads Service</h3>
                <p>
                  Facebook Ads leverage advanced targeting and diverse ad
                  formats to create customized, engaging content, boosting brand
                  awareness and driving conversions on a platform with a vast
                  user base.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
          </div>

{/* <div className="box-container" style={{backgroundColor:'red'}}> */}

<div ref={boxRef1} className="content-box">
  <div className="side-image">
    <img src={sideImage1} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
      Why is Google Ads an essential service for driving targeted traffic?
    </h1>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <TbTargetArrow />
      </i>
      <p className="p-text">
        <b>Precision Targeting :</b><br/>
        Google Ads enables highly precise audience targeting through keywords, demographics, location, and time, ensuring ads reach those most likely to be interested, thus boosting conversion chances.
      </p>
    </div>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <RiHandCoinLine />
      </i>
      <p className="p-text">
        <b>Measurable ROI :</b><br/>
        Google Ads offers robust analytics for tracking metrics like click-through and conversion rates, crucial for optimizing campaigns and ROI.
      </p>
    </div> <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <GiCash />
      </i>
      <p className="p-text">
        <b>Flexible Budgeting :</b><br/>
        Google Ads' PPC model, with adjustable bids and budgets, offers cost control, enabling businesses to efficiently drive targeted traffic.
      </p>
    </div>
  </div>
</div>


<div ref={boxRef2} className="content-box" style={{ background: 'whitesmoke', borderRadius: '6%' }}>
  <div className="side-image">
    <img src={sideImage2} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
    How does Facebook Ads service enhance brand visibility and engagement?
    </h1>
    <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <AiOutlineGlobal />
      </i>
      <p className="p-text">
        <b>Broad Reach :</b><br/>
        Leverages Facebook's vast user base to increase brand visibility across various demographics and geographies, reaching a wide and diverse audience.
      </p>
    </div>
    <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <TbTarget />
      </i>  
      <p className="p-text">
        <b>Targeted Advertising :</b><br/>
        Utilizes detailed targeting options based on interests, behaviors, and demographics to deliver ads to specific audience segments, making them more relevant and increasing engagement chances.
      </p>
    </div> 
    <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <MdConnectWithoutContact />
      </i>
      <p className="p-text">
        <b>Engagement Features :</b><br/>
        Offers interactive ad formats with call-to-action buttons and instant experiences, encouraging user interaction and driving higher engagement rates.
      </p>
    </div>
  </div>
</div>



<div ref={boxRef3} className="content-box">
  <div className="side-image">
    <img src={sideImage3} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
    Why choose Instagram Ads for creative and impactful marketing?
    </h1>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <HiOutlineSpeakerphone />
      </i>
      <p className="p-text">
        <b>
Visual Appeal :</b><br/>
Instagram’s platform thrives on visuals, allowing brands to showcase products in engaging ways. High-quality images and videos captivate viewers, making campaigns memorable.
      </p>
    </div>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <BiSolidLike />
      </i>
      <p className="p-text">
        <b>High Engagement Rates :</b><br/>
        Instagram boasts high user engagement, with content often receiving more likes, comments, and shares. This active interaction amplifies brand visibility and strengthens consumer connections.
      </p>
    </div> <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <IoIosPeople />
      </i>
      <p className="p-text">
        <b>Advanced Targeting Options :</b><br/>
        Instagram offers sophisticated targeting, leveraging Facebook’s data for precise audience selection. This ensures ads reach those most interested, maximizing campaign effectiveness.
      </p>
    </div>

    

  </div>
</div>

{/* </div> */}

        </section>
      </div>
   
      </div>

      <div className="footer">
        <center>
<Footer/>
</center>
</div>
    
    </>
  );
};

export default Digitalmarketing;