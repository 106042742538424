// import React ,{ useState }from 'react'
// import Navbar from '../Components/Navbar';
// import Footer from '../Components/Footer';

// const Career = () => {
//   const [fullName, setFullName] = useState('');
//   const [email, setEmail] = useState('');
//   const [mobile, setMobile] = useState('');
//   const [message, setMessage] = useState('');

  
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//   const phoneRegex = /^\d{10}$/;

//   const handleEmailChange = (e) => {
//     const value = e.target.value;
//     setEmail(value);
//   };

//   const handleMobileChange = (e) => {
//     const value = e.target.value;
//     setMobile(value);
//   };

//   return (
//     <>
//     <Navbar/>
//     <div id='contact' style={{marginBottom:'20vh'}}>
//     <h1>Career With Us</h1>
//     <form action='https://formspree.io/f/xjvnyrao' method='POST'>
//       <input
//         type='text'
//         placeholder='Full Name'
//         name='fullname'
//         autoComplete='off'
//         value={fullName}
//         onChange={(e) => setFullName(e.target.value)}
//         required
//       />
//       <input
//         type='email'
//         placeholder='Type Your E-mail'
//         name='email'
//         autoComplete='off'
//         value={email}
//         onChange={handleEmailChange}
//         required
//       />
//       {email && !emailRegex.test(email) && <span className="error-message">Enter valid email </span>}
//       <input
//         type='text'
//         placeholder='Enter Mobile Number'
//         name='mobile'
//         autoComplete='off'
//         value={mobile}
//         onChange={handleMobileChange}
//         required
//       />
//       {mobile && !phoneRegex.test(mobile) && <span className="error-message">Enter Valid mobile number</span>}
//       <textarea
//         placeholder='Give Your Introduction'
//         name='message'
//         autoComplete='off'
//         value={message}
//         onChange={(e) => setMessage(e.target.value)}
//       ></textarea>
//       <input type='submit' value='send' />
//     </form>
//     </div>
//     <Footer/>
//     </>
//   )
// }

// export default Career



import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const Career = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [jobRole, setJobRole] = useState(''); // Added state for job role

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{10}$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  
  const handleJobRoleChange = (e) => {
    setJobRole(e.target.value);
  };

  return (
    <>
      <Navbar />
      <div id='contact' style={{ marginBottom: '20vh' }}>
        <h1>Career With Us</h1>
        <form action='https://formspree.io/f/xrgnawlb' method='POST'>
          <input
            type='text'
            placeholder='Full Name'
            name='fullname'
            autoComplete='off'
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
          <input
            type='email'
            placeholder='Type Your E-mail'
            name='email'
            autoComplete='off'
            value={email}
            onChange={handleEmailChange}
            required
          />
          {email && !emailRegex.test(email) && <span className="error-message">Enter valid email</span>}
          <input
            type='text'
            placeholder='Enter Mobile Number'
            name='mobile'
            autoComplete='off'
            value={mobile}
            onChange={handleMobileChange}
            required
          />
          {mobile && !phoneRegex.test(mobile) && <span className="error-message">Enter Valid mobile number</span>}
          <select name='jobRole' value={jobRole} onChange={handleJobRoleChange} required >
            <option value=''>Select Job Role</option>
            <option value='seo-specialist'>SEO Specialist</option>
            <option value='facebook-ads-expert'>Facebook Ads Experts</option>
            <option value='social-media-manager'>Social Media Manager</option>
            <option value='graphic-designer'>Graphic Designer</option>
          </select>
          <textarea
            placeholder='Give Your Introduction'
            name='message'
            autoComplete='off'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <input type='submit' value='send' />
        </form>
      </div>
      <Footer />
    </>
  )
}

export default Career;

