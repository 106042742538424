// import React from 'react';
// import './Ourclient.css';
// import logo1 from "../images/shivay.png";
// import logo2 from "../images/lac.png";
// import logo3 from "../images/glp.png";

// const OurClient = () => {
//   return (

//     <>
//   <h3 className='titletext'>WE WORK FOR POPULAR BRANDS</h3>
//     <section className="logoMarqueeSection">
  
//       <div className="container" id="logoMarqueeSection">
//         <div className="default-content-container flex items-center">
//           <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
//             <div className="marquee" style={{ animationDuration: '57s' }}>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo1} alt="Logo" className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo2} alt="Logo" className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo3} alt="Logo" className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo1} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo2} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo3} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo1} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo2} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo3} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo1} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo2} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
//               <a target="_blank" rel="noopener noreferrer"><img src={logo3} alt="Logo"  className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} /></a>
             
//             </div>
//             {/* Repeat for the second marquee div if needed */}

//           </div>
//         </div>
//       </div>
//     </section>
//     </>
//   );
// };

// export default OurClient;


import React from 'react';
import './Ourclient.css'; // Make sure to create this CSS file
import logo1 from "../images/shivay.png";
import logo2 from "../images/lac.png";
import logo3 from "../images/glp.png";
import logo4 from "../images/fusionmasti.png";
import logo5 from "../images/shivay.png";
import logo6 from "../images/fasdel.png";
import logo7 from "../images/kanak.png";
import logo8 from "../images/aliens.png";

const logos = [
logo1,logo2,logo3,logo4,logo5,logo6,logo7,logo8
];

const OurClient = () => {
  return (
    <>
     <h3 className='titletext'>We Work For Popular Brands</h3>
    <div className="marquee">
      <div className="marquee-content">
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`Logo ${index + 1}`} />
        ))}
        {/* Duplicate logos for seamless transition */}
        {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo ${index + 1}`} />
        ))}

        {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo ${index + 1}`} />
        ))}

        {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo ${index + 1}`} />
        ))}

{logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo ${index + 1}`} />
        ))}  {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo ${index + 1}`} />
        ))}  {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo ${index + 1}`} />
        ))}  {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo ${index + 1}`} />
        ))}  {logos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Logo ${index + 1}`} />
        ))}
      </div>
    </div>
    </>
  );
};

export default OurClient;



