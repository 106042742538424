import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import image11 from "../images/smback.jpg";
import mission from "../images/missionicon.png";
import vision from "../images/visionicon.png";
import value from "../images/valueicon.png";
import "./about.css";

import star from "../images/star.png";
import mainimg from "../images/pexels-fauxels-3184418.jpg";

const About = () => {
  return (
    <div className="App">
      <Navbar />

      <div classNameName="container">
        <div style={{ position: "relative" }}>
          <img
            src={image11}
            style={{ opacity: "0.5" }}
            alt="Service Background"
            width="1700px"
            height="700px"
          />
          <div className="overlay-text">
            <h1
              className="animated-text"
              style={{ textAlign: "center", color: "white" }}
            >
              About Us
            </h1>
          </div>
        </div>
      </div>

      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container bottomContainer">
          <div className="ultimateImg">
            <img className="mainImg" src={mainimg} />
            <div className="purpleBox">
              <p className="purpleText">
                Boost your business with our 5-star services – as rated by
                satisfied customers on Google Maps!
              </p>
              <img className="stars" src={star} />
            </div>
          </div>
          <div className="allText bottomText">
            <p className="text-blk headingText">About Us</p>
            <p className="text-blk subHeadingText">
              Expand Your Reach, Elevate Your Brand with Us!
            </p>
            <p className="text-blk description">
              Linkview Digital Hub, a Surat-based leader in digital marketing
              and branding, boasts over 6 years of industry experience. We offer
              a full suite of services including Search Marketing, Lead
              Generation, Social Media, and Email Marketing. Our integrated
              approach ensures enhanced brand recall and superior ROI for our
              diverse clientele.
            </p>
            <a className="explore" href="tel:+918980639900">
              Call Now
            </a>
          </div>
        </div>
      </div>

      <section className="box-container">
        <div class="services-grid">
          <div class="service service1">
            <img class="ti-bar-chart" src={vision} />
            <h4>Our Vision</h4>
            <p style={{color:'white'}}>
            Driving the future of finance with cutting-edge strategies and exceptional service, we aim to be the global beacon for wealth empowerment and innovation.
            </p>
            <a href="https://wa.me/918490084721?text=Hello%20Linkview%20Digital%20Hub!" class="cta" target="_blank">
            Contact Now <span class="ti-angle-right"></span>
            </a>
          </div>

          <div className="service service2">
          <img class="ti-bar-chart" src={mission} />
            <h4>Our Mission</h4>
            <p style={{color:'white'}}>
            We are commit to transforming our client's financial dreams into reality with precision, integrity, and personalized service that caters to individual goals.
            </p>
            <a href="https://wa.me/918490084721?text=Hello%20Linkview%20Digital%20Hub!" className="cta" target="_blank">
            Contact Now <span className="ti-angle-right"></span>
            </a>
          </div>

          <div className="service service3">
          <img class="ti-bar-chart" src={value} />
            <h4>Our Values</h4>
            <p style={{color:'white'}}>
            Unwavering dedication to our clients' success defines us. We champion excellence, uphold integrity, and embrace innovation in every financial solution.
            </p>
            <a href="https://wa.me/918490084721?text=Hello%20Linkview%20Digital%20Hub!" className="cta" target="_blank">
            Contact Now <span className="ti-angle-right"></span>
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
