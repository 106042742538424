import React, { useEffect, useRef } from "react";
import "./service.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { MdOutlineVideoSettings } from "react-icons/md";
import { MdOndemandVideo } from "react-icons/md";
import { FaCameraRetro } from "react-icons/fa";
import { Link } from "react-router-dom";
import sideImage1 from "../../images/prof_video.png";
import sideImage2 from "../../images/adv_video.png";
import sideImage3 from "../../images/sideimg3.png";


import { TbTargetArrow } from "react-icons/tb";
import { RiHandCoinLine } from "react-icons/ri";
import { GiCash } from "react-icons/gi";


const Videoediting = () => {
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);

  const checkScroll = () => {
    [boxRef1, boxRef2, boxRef3].forEach((ref) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          ref.current.classList.add("in-view");
        } else {
          ref.current.classList.remove("in-view");
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <>
      <Navbar />
     <div className="main-container">

      <div className="service-pages">

        <section id="home" className="home">
          <h1>Services &gt;</h1>
          <h2>Video Editing</h2>
          <div className="wave wave1"></div>
          <div className="wave wave2"></div>
          <div className="wave wave3"></div>
        </section>

        <h1 className="service-text">We Provide</h1>
        <section className="service-section">
          <div className="row">
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <MdOutlineVideoSettings />
                  </i>
                </div>
                 <br/>
                <h3>Professional Video Editing</h3>
                <p>
                Elevate your brand with our top-notch Video Editing Services. Transform raw footage into captivating stories. Engage and impress your audience. Let's create magic together!
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <MdOndemandVideo />
                  </i>
                </div>
                 <br/>
                <h3>Advanced Editing</h3>
                <p>
                  Elevate your videos with our Advanced Editing services: expert
                  color correction for cinematic visuals, pristine sound
                  editing, and accessible subtitles. Transform your content,
                  captivate your audience.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaCameraRetro />
                  </i>
                </div>
                 <br/>
                <h3>Specialized Video Services</h3>
                <p>
                Offer specialized video services for needs: corporate, event, promotional, educational, social media, real estate, and music. Elevate your message, engage your audience, and achieve goals with tailored storytelling.
                </p>
                
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
          </div>

          <div ref={boxRef1} className="content-box">
  <div className="side-image">
    <img src={sideImage1} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
    Why should I choose your Professional Video Editing Services for my content?

    </h1>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <TbTargetArrow />
      </i>
      <p className="p-text">
        <b>High-Quality Editing Expertise:
 </b><br/>
 Choosing our Professional Video Editing Services ensures access to skilled editors delivering high-quality edits, enhancing your content's visual appeal through polished editing techniques.
      </p>
    </div>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <RiHandCoinLine />
      </i>
      <p className="p-text">
        <b>Efficient Turnaround Time:
</b><br/>
Benefit from our efficient turnaround time, ensuring timely delivery without quality compromise, allowing prompt content publishing or sharing.
      </p>
    </div> <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <GiCash />
      </i>
      <p className="p-text">
        <b>Customized Editing Solutions:
</b><br/>
Our video editing services are tailored to your needs, offering personalized solutions for promotional, educational, or social media content. We align our editing with your brand and audience goals for maximum impact.

      </p>
    </div>
  </div>
</div>


<div ref={boxRef2} className="content-box" style={{ background: 'whitesmoke', borderRadius: '6%' }}>
  <div className="side-image">
    <img src={sideImage2} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
    What makes your Advanced Editing services essential for my video content?


    </h1>
    <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <TbTargetArrow />
      </i>
      <p className="p-text">
        <b>Cinematic Precision and Creativity:

 </b><br/>
 Cinematic precision and creativity elevate your videos with intricate transitions and dynamic effects, captivating viewers' attention.
      </p>
    </div>
    <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <RiHandCoinLine />
      </i>
      <p className="p-text">
        <b>Specialized Visual Enhancements:

</b><br/>
Specialized visual enhancements, like color grading and 3D animations, give your videos a polished and immersive look, ensuring a memorable viewer experience.
      </p>
    </div> <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <GiCash />
      </i>
      <p className="p-text">
        <b>Industry-Leading Software and Technology:

</b><br/>
Industry-leading software and technology ensure cutting-edge results, providing innovative editing solutions for professional-quality content.

      </p>
    </div>
  </div>
</div>


<div ref={boxRef3} className="content-box">
  <div className="side-image">
    <img src={sideImage3} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
    How do your Specialized Video Services cater to diverse content needs?



    </h1>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <TbTargetArrow />
      </i>
      <p className="p-text">
        <b>Customized Solutions for Varied Industries:


 </b><br/>
 
Customized video solutions for diverse industries, adapting to specific needs and resonating effectively with audiences.
      </p>
    </div>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <RiHandCoinLine />
      </i>
      <p className="p-text">
        <b>Adaptive Editing Styles and Genres:


</b><br/>
Adaptive editing styles cater to various content genres, ensuring your videos stand out while aligning with genre expectations.
      </p>
    </div> <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <GiCash />
      </i>
      <p className="p-text">
        <b>Multi-Platform Optimization:


</b><br/>
Multi-platform optimization tailors content for social media, websites, presentations, and broadcast, maximizing impact across channels.

      </p>
    </div>
  </div>
</div>

        </section>
      </div>

      </div>
     

      <div className="footer">
        <center>
<Footer/>
</center>
</div>
    </>
  );
};

export default Videoediting;
