

import React, { useEffect, useRef } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { FaLaptopCode } from "react-icons/fa6";
import "./service.css";

import { FaLink } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";

import { TbTargetArrow } from "react-icons/tb";
import { RiHandCoinLine } from "react-icons/ri";
import { GiCash } from "react-icons/gi";

import { Link } from "react-router-dom";
import sideImage1 from "../../images/onpageseo.png";
import sideImage2 from "../../images/offpageseo.png";
import sideImage3 from "../../images/localseo.png";

const Seo = () => {
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);

  const checkScroll = () => {
    [boxRef1, boxRef2, boxRef3].forEach((ref) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
          ref.current.classList.add("in-view");
        } else {
          ref.current.classList.remove("in-view");
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <>
       <Navbar />
     <div className="main-container">

      <div className="service-pages">

        <section id="home" className="home">
          <h1>Services &gt;</h1>
          <h2>Search Engine Optimization</h2>
          <div className="wave wave1"></div>
          <div className="wave wave2"></div>
          <div className="wave wave3"></div>
        </section>

        <h1 className="service-text">We Provide</h1>
        <section className="service-section">
          <div className="row">
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaLaptopCode />
                  </i>
                </div>
                 <br/>
                <h3>On-Page SEO</h3>
                <p>
                Focuses on optimizing elements on your website for search engines, including content, HTML source code, images, and tags. It involves keyword research, content creation, and keyword optimization.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaLink />
                  </i>
                </div>
                 <br/>
                <h3>Off-Page SEO</h3>
                <p>
                Involves actions outside your website to impact rankings within search engine results pages. This includes link building, improving position through backlinks from reputable sites, and social media marketing.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon-wrapper">
                  <i className="serviceicon">
                    <FaMapMarkedAlt />
                  </i>
                </div>
                 <br/>
                <h3>Local SEO</h3>
                <p>
                Optimizes your online presence to attract more business from relevant local searches. This includes optimizing your Google My Business listing, local citations, and local keywords optimization.
                </p>
                <Link to="/contact">
                  <h4>Send Inquiry</h4>
                </Link>
              </div>
            </div>
          </div>

          <div ref={boxRef1} className="content-box">
  <div className="side-image">
    <img src={sideImage1} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
    Why is On-Page SEO crucial for my website's success?
    </h1>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <TbTargetArrow />
      </i>
      <p className="p-text">
        <b>Strategic Content Optimization: </b><br/>
        On-Page SEO is essential for content optimization, targeting relevant keywords to enhance visibility and attract the right audience, improving search engine rankings.
      </p>
    </div>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <RiHandCoinLine />
      </i>
      <p className="p-text">
        <b>User Experience Enhancement:</b><br/>
        Improving user experience is key, with On-Page SEO optimizing page structure and usability, leading to higher engagement and conversion rates.
      </p>
    </div> <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <GiCash />
      </i>
      <p className="p-text">
        <b>Search Engine Crawlability and Indexing:</b><br/>
        It also enhances search engine crawlability and indexing, ensuring content is easily understood and ranked, boosting visibility in search results.
      </p>
    </div>
  </div>
</div>


<div ref={boxRef2} className="content-box" style={{ background: 'whitesmoke', borderRadius: '6%' }}> 
  <div className="side-image">
    <img src={sideImage2} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
    How does Off-Page SEO enhance my website's online authority?
    </h1>
    <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <TbTargetArrow />
      </i>
      <p className="p-text">
        <b>Backlink Building Strategies:
 </b><br/>
       
Off-Page SEO boosts website authority via strategic backlink building. Quality backlinks from reputable sites signal content value and trust to search engines. Techniques like guest posting and content partnerships enhance authority.
      </p>
    </div>
    <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <RiHandCoinLine />
      </i>
      <p className="p-text">
        <b>Social Media Engagement:

</b><br/>
Social media engagement increases website authority. Active participation and valuable content sharing on social platforms improve reach and credibility, influencing rankings through social signals.
      </p>
    </div> <div className="icon-paragraph-container" style={{ color: 'black' }}>
      <i className="sicons left-icon">
        <GiCash />
      </i>
      <p className="p-text">
        <b>Online Reviews:

</b><br/>
Managing online reviews boosts authority and trust. Positive reviews on platforms like Google My Business enhance online presence and influence search engine rankings.

      </p>
    </div>
  </div>
</div>

<div ref={boxRef3} className="content-box">
  <div className="side-image">
    <img src={sideImage3} alt="Service" />
  </div>

  <div className="side-text">
    <h1>
    Why is Local SEO important for attracting more customers to my business?

    </h1>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <TbTargetArrow />
      </i>
      <p className="p-text">
        <b>Increased Local Visibility:
</b><br/>
Local SEO enhances your business's visibility in your geographic area, making it easier for local customers to find you through optimized location-based keywords, a Google My Business profile, and updated local directories.
      </p>
    </div>
    <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <RiHandCoinLine />
      </i>
      <p className="p-text">
        <b>Positive Impact on Local Reputation:

</b><br/>
Optimizing for mobile searches is crucial as many users search for nearby businesses on their devices. A strong local SEO strategy ensures your business appears prominently in these searches, increasing foot traffic and conversions.
      </p>
    </div> <div className="icon-paragraph-container">
      <i className="sicons left-icon">
        <GiCash />
      </i>
      <p className="p-text">
        <b>Search Engine Crawlability and Indexing:</b><br/>
        Local SEO boosts your online reputation within your community. Encouraging reviews on platforms like Google and Yelp not only improves visibility but also builds trust and credibility, attracting new customers.
      </p>
    </div>
  </div>
</div>

         
        </section>
      </div>
</div>
<div className="footer">
        <center>
<Footer/>
</center>
</div>
    </>
  );
};

export default Seo;
