import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";

import Services from "./Pages/Services";
import Faq from "./Pages/Faq";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Blog from "./Pages/blog";

import Blog1 from "./Pages/Blogpages/Blog1";
import Blog2 from "./Pages/Blogpages/Blog2";
import Blog3 from "./Pages/Blogpages/Blog3";
import Blog4 from "./Pages/Blogpages/Blog4";
import Blog5 from "./Pages/Blogpages/Blog5";
import Blog6 from "./Pages/Blogpages/Blog6";


import Websitedesign from "./Pages/servicepages/Websitedesign";
import Videoediting from "./Pages/servicepages/Videoediting";
import Seo from "./Pages/servicepages/Seo";
import Graphicdesign from "./Pages/servicepages/Graphicdesign";
import Digitalmarketing from "./Pages/servicepages/Digitalmarketing";
import Branding from "./Pages/servicepages/Branding";
import Privacy from "./Pages/Privacy";
import Term from "./Pages/Term";
import Career from "./Pages/Career";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
   
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/services" element={<Services />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />

  <Route path="/blogone" element={<Blog1/>}></Route>
  <Route path="/blogtwo" element={<Blog2/>}></Route>
  <Route path="/blogthree" element={<Blog3/>}></Route>
  <Route path="/blogfour" element={<Blog4/>}></Route>
  <Route path="/blogfive" element={<Blog5/>}></Route>
  <Route path="/blogsix" element={<Blog6/>}></Route>


  <Route path="/privacy-policy" element={<Privacy/>}></Route>
  <Route path="/terms-and-conditions" element={<Term/>}></Route>
  <Route path="/career-with-us" element={<Career/>}></Route>
  <Route path="*" element={<App/>}></Route>




{/* services pages  */}
  <Route path="/webdesign" element={<Websitedesign/>}></Route>
  <Route path="/videoediting" element={<Videoediting/>}></Route>
  <Route path="/seo" element={< Seo/>}></Route>
  <Route path="/graphicdesign" element={<Graphicdesign/>}></Route>
  <Route path="/digitalmarketing" element={<Digitalmarketing/>}></Route>
  <Route path="/branding" element={<Branding/>}></Route>  
  

      </Routes>
    
    </BrowserRouter>
  </>
);

